import { Spinner, useDisclosure, useToast, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import CreateCoach from '../dialogueBoxes/CreateCoach';
import { UserState } from '../../context/user';
import { useTranslation } from 'react-i18next';

const statusRoleMapping = {
    'ONLINE-STORE-MANAGER-REVIEW': 'APPROVED',
};

const roleToTypeAndStatus = {
    'ONLINE-STORE-MANAGER': 'ONLINE-STORE-MANAGER-REVIEW',
};

const ViewExternalUserOrderRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getRequestDetails = async () => {
        const res = await get(`request/order-product/get/${requestId}`);
        if (res.statusCode === 200) {
            setRequest(res.data.request);
            const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request.status;
            setCanApprove(userCanApprove);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    const handleApprove = async () => {
        try {
            const nextStatus = statusRoleMapping[request.status];

            if (!nextStatus) {
                toast({
                    title: t('error'),
                    description: t('cannot-approve-request'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            setLoading(true);
            const res = await patch(`request/approved/order-product/${requestId}`, { status: nextStatus });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
            } else {
                toast({
                    title: t('error'),
                    description: t('failed-to-approve-request'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: t('error'),
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update/order-product/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            if (res.statusCode === 200) {
                setLoading(false);
                getRequestDetails();
                getAllRequest();
                onClose();
                toast({
                    title: t('success'),
                    description: t('request-rejected'),
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                onClose();
                toast({
                    title: t('error'),
                    description: t('failed-to-reject-request'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: t('error'),
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const confirmApprove = () => {
        handleApprove();
        onClose();
    };

    const handleApproveRejectRequest = (type) => {
        if (type === "APPROVED") {
            setRequestAction(true);
            onOpen();
        } else {
            setRequestAction(false);
            onOpen();
        }
    };

    return (
        <div className={`bg-white p-6 rounded-lg shadow-md mx-auto ${isArabic ? 'text-right' : 'text-left'}`}>
            <div className='w-full flex justify-between items-center mb-4'>
                <button onClick={() => setView(false)} className='bg-blue-600 text-white px-4 py-2 font-medium text-base rounded-md flex items-center'>
                    <IoMdArrowRoundBack className={`mr-2 ${isArabic ? 'ml-2 mr-0' : ''}`} /> {t('back')}
                </button>
                {canApprove && (
                    <div>
                        <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-red-600 text-white px-4 py-2 font-medium text-base rounded-md mr-2'>
                            {loading ? <Spinner /> : t("reject-request")}
                        </button>
                        <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-blue-600 text-white px-4 py-2 font-medium text-base rounded-md'>
                            {loading ? <Spinner /> : t("approve-request")}
                        </button>
                    </div>
                )}
                {request.status === "HR-MANAGER-HIRING" && <CreateCoach byHr={true} requestId={requestId} getRequestDetails={getRequestDetails} />}
            </div>

            <div className="mb-4" dir={isArabic ? 'rtl' : 'ltr'}>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">{t('request-details')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('requested-by')} :</h3>
                        <p className="text-gray-700">{request?.requestedBy?.name}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('request-status')} :</h3>
                        <p className={`text-lg font-medium ${request?.status === "APPROVED" ? 'text-green-600' : request?.status === "REJECTED" ? 'text-red-600' : 'text-yellow-600'}`}>
                            {t(request?.status)}
                        </p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('request-type')}:</h3>
                        <p className="text-gray-700">{request?.kind}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('description')}:</h3>
                        <p className="text-gray-700">{request?.description}</p>
                    </div>
                </div>
            </div>

            <div className="mb-4" dir={isArabic ? 'rtl' : 'ltr'}>
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{t('external-user-details')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('name')}</h3>
                        <p className="text-gray-700">{request?.externalUserId?.name}</p>
                    </div>

                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('email')}</h3>
                        <p className="text-gray-700">{request?.externalUserId?.email}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="font-semibold text-lg">{t('mobile')}</h3>
                        <p className="text-gray-700">{request?.externalUserId?.mobile}</p>
                    </div>
                </div>
            </div>

            <div className="mb-4" dir={isArabic ? 'rtl' : 'ltr'}>
                <div className='flex'>
                    <h2 className="text-xl font-semibold text-gray-800 mb-2">{t('products-requested-with-a-total-of')} SAR {request.totalAmount}</h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 items-end">
                    {request?.productIds?.map(item => (
                        <div key={item._id} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                            <h3 className="font-semibold text-xl text-gray-900 mb-2">{item?.productId?.name}</h3>
                            <div className="mt-3 space-y-2">
                                <img
                                    src={item.productId?.images[0]}
                                    alt={item.productId?.name}
                                    className="rounded-lg object-cover h-40 w-full transition-transform duration-300 transform hover:scale-105"
                                />
                            </div>
                            <div className='flex justify-between items-center flex-row-reverse'>
                                <div className="mt-4 flex">
                                    <span className="block text-gray-600 font-medium">{t('quantity')}: {item?.quantity}</span>
                                </div>
                                <div className="mt-4 flex space-x-4">
                                    <span className="block text-gray-600 font-medium">{t('color')}:</span>
                                    <Box backgroundColor={item?.color} width={"10"} ></Box>
                                </div>
                                <div className="mt-4 flex space-x-4">
                                    <span className="block text-gray-600 font-medium">{t('size')}:</span>
                                    <span className="block text-gray-900 font-semibold">{item?.size}</span>
                                </div>
                            </div>
                            <div className="mt-3 space">
                                <span className="block text-gray-600 font-bold">{t('customizastion')}:</span>
                                <span className="block text-gray-600 font-medium">{item?.customization}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <CustomAlert
                isOpen={isOpen}
                onClose={onClose}
                title={t(requestAction ? "approve-request" : "reject-request")}
                confirmApprove={confirmApprove}
                handleReject={handleReject}
                actionType={requestAction}
            />
        </div>
    );
};

export default ViewExternalUserOrderRequest;
