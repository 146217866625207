import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { EmployeeState } from '../../../../context/addEmployee';

const Basics = () => {
    const { basicDetails, setBasicDetails } = EmployeeState();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setBasicDetails((prevDetails) => ({
            ...prevDetails,
            [id]: value,
        }));
    };

    return (
        <Box mx="auto" p={4}>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6}>
                <GridItem>
                    <FormControl id="fullName">
                        <FormLabel>Full Name</FormLabel>
                        <Input
                            type="text"
                            placeholder="Enter full name"
                            value={basicDetails.fullName || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="email">
                        <FormLabel>Email ID</FormLabel>
                        <Input
                            type="email"
                            placeholder="Enter email ID"
                            value={basicDetails.email || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="contactNo">
                        <FormLabel>Contact No</FormLabel>
                        <Input
                            type="number"
                            placeholder="Enter contact number"
                            value={basicDetails.contactNo || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="department">
                        <FormLabel>Department</FormLabel>
                        <Select
                            placeholder="Select department"
                            value={basicDetails.department || ''}
                            onChange={handleChange}
                        >
                            <option>Marketing</option>
                            <option>Sales</option>
                            <option>HR</option>
                            <option>Finance</option>
                            <option>Engineering</option>
                            <option>Other</option>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="type">
                        <FormLabel>Type</FormLabel>
                        <Select
                            placeholder="Select type"
                            value={basicDetails.type || ''}
                            onChange={handleChange}
                        >
                            <option>Full-time</option>
                            <option>Part-time</option>
                            <option>Contract</option>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="designation">
                        <FormLabel>Designation</FormLabel>
                        <Input
                            type="text"
                            placeholder="Enter designation"
                            value={basicDetails.designation || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="gender">
                        <FormLabel>Gender</FormLabel>
                        {/* <Input
                            type="text"
                            placeholder="Enter employee code"
                            value={basicDetails.employeeCode || ''}
                            onChange={handleChange}
                        /> */}
                        <Select
                            placeholder="Select Gender"
                            value={basicDetails.gender || ''}
                            onChange={handleChange}
                        >
                            <option>MALE</option>
                            <option >FEMALE</option>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="joiningDate">
                        <FormLabel>Joining Date</FormLabel>
                        <Input
                            type="date"
                            placeholder="Enter joining date"
                            value={basicDetails?.joiningDate ? new Date(basicDetails?.joiningDate).toISOString().split('T')[0] : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="location">
                        <FormLabel>Location</FormLabel>
                        <Input
                            type="text"
                            placeholder="Enter location"
                            value={basicDetails.location || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
            </SimpleGrid>
        </Box>
    );
};

export default Basics;
