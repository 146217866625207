import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';

const Performances = () => {
  const data = [
    {
      profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
      name: 'Ashu Aggrawal',
      employeeCode: 'MASE0009',
      designation: 'Employee',
      todos: '15/20',
      leaves: 2,
      reportingHeadPP: 7.8,
      performance: '',
    },
    {
      profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
      name: 'Sumit Khare',
      employeeCode: 'SASLSI101',
      designation: 'SASLSI101',
      todos: '23/27',
      leaves: 0,
      reportingHeadPP: 6,
      performance: '',
    },
    {
      profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
      name: 'Gargi Jain',
      employeeCode: 'PNPVD2',
      designation: 'PNPVD2',
      todos: '18/20',
      leaves: 1,
      reportingHeadPP: 9.2,
      performance: '',
    },
  ];

  return (
    <Box width="100%" p={4}>
        <Flex justify="space-between" align="center" mb={4}>
            <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                Performances
            </Box>
            <Flex align="center">
                <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder="Search"
                        value={""}
                        onChange={()=>{}}
                    />
                </InputGroup>
                <Select placeholder="2023" w="100px" mr={2}>
                    {/* Add more years as options */}
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                </Select>
                <Select placeholder="January" w="150px" mr={2}>
                    {/* Add all months as options */}
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                </Select>
                <Button leftIcon={<DownloadIcon />} colorScheme="gray" mb={{ base: 2, md: 0 }}>
                    Download CSV
                </Button>
            </Flex>
        </Flex>
        <Table className="w-full text-sm text-left text-gray-500">
            <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
                <Th>Profile</Th>
                <Th>Name</Th>
                <Th>Employee Code</Th>
                <Th>Designation</Th>
                <Th>Todos</Th>
                <Th>Leaves</Th>
                <Th>Reporting Head PP</Th>
                <Th>Performance</Th>
            </Tr>
            </Thead>
            <Tbody>
            {data.map((row, index) => (
                <Tr key={index}>
                <Td>
                    <Avatar src={row.profile} name={row.name} />
                </Td>
                <Td>{row.name}</Td>
                <Td>{row.employeeCode}</Td>
                <Td>{row.designation}</Td>
                <Td>{row.todos}</Td>
                <Td>{row.leaves}</Td>
                <Td>{row.reportingHeadPP}</Td>
                <Td>
                    <Input placeholder="Enter performance" value={row.performance} />
                </Td>
                </Tr>
            ))}
            </Tbody>
        </Table>
    </Box>
  );
};

export default Performances;
