import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    IconButton,
    Grid,
    GridItem,
    Tooltip,
} from '@chakra-ui/react';
import { patch, post } from '../../../services/apis/api';
import { EditIcon } from '@chakra-ui/icons';

const CreateAsset = ({ getAssets, isEdit, asset }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        model: '',
        serialNumber: '',
        purchaseDate: '',
        status: 'available',
        condition: 'excellent',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (isEdit) {
            setFormData({
                name: asset?.name || '',
                description: asset?.description || '',
                category: asset?.category || '',
                model: asset?.model || '',
                serialNumber: asset?.serialNumber || '',
                purchaseDate: asset?.purchaseDate || '',
                status: asset?.status || 'available',
                condition: asset?.condition || 'excellent',
            });
        }
    }, [isEdit, asset]);

    const handleSubmit = async () => {
        try {
            let response;
            if (isEdit) {
                response = await patch(`assets/update/${asset._id}`, formData);
            } else {
                response = await post('assets/add', formData);
            }

            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Asset saved.',
                    description: 'The asset has been saved successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getAssets();
                setFormData({
                    name: '',
                    description: '',
                    category: '',
                    model: '',
                    serialNumber: '',
                    purchaseDate: '',
                    status: 'available',
                    condition: 'excellent',
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to save the asset.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div>
            {
                isEdit ? 
                <Tooltip label='Edit Asset'>
                    <IconButton
                        icon={<EditIcon />}
                        onClick={onOpen}
                        aria-label="Edit Asset"
                    /> 
                </Tooltip>
                : 
                <Button onClick={onOpen} colorScheme="blue">
                    Add Asset
                </Button>
            }

            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isEdit ? 'Edit Asset' : 'Add Asset'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <GridItem>
                                <FormControl id="name" isRequired>
                                    <FormLabel>Asset Name</FormLabel>
                                    <Input
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder="Enter asset name"
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="category" isRequired>
                                    <FormLabel>Category</FormLabel>
                                    <Input
                                        name="category"
                                        value={formData.category}
                                        onChange={handleInputChange}
                                        placeholder="Enter category"
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="model">
                                    <FormLabel>Model</FormLabel>
                                    <Input
                                        name="model"
                                        value={formData.model}
                                        onChange={handleInputChange}
                                        placeholder="Enter model"
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="serialNumber">
                                    <FormLabel>Serial Number</FormLabel>
                                    <Input
                                        name="serialNumber"
                                        value={formData.serialNumber}
                                        onChange={handleInputChange}
                                        placeholder="Enter serial number"
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="purchaseDate">
                                    <FormLabel>Purchase Date</FormLabel>
                                    <Input
                                        type="date"
                                        name="purchaseDate"
                                        value={formData.purchaseDate ? new Date(formData.purchaseDate).toISOString().split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="status" isRequired>
                                    <FormLabel>Status</FormLabel>
                                    <Select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                    >
                                        <option value="available">Available</option>
                                        <option value="assigned">Assigned</option>
                                        <option value="under-maintenance">Under Maintenance</option>
                                        <option value="returned">Returned</option>  
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl id="condition" isRequired>
                                    <FormLabel>Condition</FormLabel>
                                    <Select
                                        name="condition"
                                        value={formData.condition}
                                        onChange={handleInputChange}
                                    >
                                        <option value="poor">Poor</option>
                                        <option value="fair">Fair</option>
                                        <option value="good">Good</option>
                                        <option value="excellent">Excellent</option>
                                    </Select>
                                </FormControl>
                            </GridItem>

                            <GridItem colSpan={2}>
                                <FormControl id="description">
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        placeholder="Enter description"
                                    />
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mx={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default CreateAsset;
