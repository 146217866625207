import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Role from '../pages/operations/Role';
import Home from '../pages/operations/Home';
import ApplicationService from '../pages/operations/ApplicationService';
import ExternalUser from '../pages/operations/ExternalUser';
// import Clubs from '../pages/operations/Clubs';

const Operation = () => {
  return (
    <Routes>
        <Route path="/operation" element={<CommanNav />}>
            <Route index element={<Home />} />
            <Route path="role" element={<Role />} />
            <Route path="external-user" element={<ExternalUser />} />
            <Route path="application-service" element={<ApplicationService />} />
            {/* <Route path="clubs" element={<Clubs />} /> */}
        </Route>
    </Routes>
  )
}

export default Operation