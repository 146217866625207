import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';

const Reports = () => {
    const data = [
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ashu Aggrawal',
            employeeCode: 'MASE0009',
            attendance: '25/30',
            penalties: 1,
            onTimeLate: '20/5',
            performance: '9.5',
            overtimeHours: 5,
            goalAchieved: '90%',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Sumit Khare',
            employeeCode: 'SASLSI101',
            attendance: '28/30',
            penalties: 0,
            onTimeLate: '28/2',
            performance: '9.0',
            overtimeHours: 3,
            goalAchieved: '85%',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Gargi Jain',
            employeeCode: 'PNPVD2',
            attendance: '29/30',
            penalties: 2,
            onTimeLate: '26/4',
            performance: '8.5',
            overtimeHours: 4,
            goalAchieved: '88%',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ravi Sharma',
            employeeCode: 'MASR009',
            attendance: '26/30',
            penalties: 1,
            onTimeLate: '24/6',
            performance: '9.2',
            overtimeHours: 6,
            goalAchieved: '92%',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Neha Mehta',
            employeeCode: 'NEHM101',
            attendance: '30/30',
            penalties: 0,
            onTimeLate: '30/0',
            performance: '9.8',
            overtimeHours: 7,
            goalAchieved: '95%',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Vikas Patel',
            employeeCode: 'VIKP102',
            attendance: '27/30',
            penalties: 1,
            onTimeLate: '25/5',
            performance: '8.9',
            overtimeHours: 2,
            goalAchieved: '87%',
        },
    ];

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                    Payroll
                </Box>
                <Flex align="center">
                    <Button colorScheme="blue" mr={2}>
                        Generate Payslips
                    </Button>
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={""}
                            onChange={()=>{}}
                        />
                    </InputGroup>
                    <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                        Download CSV
                    </Button>
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>Profile</Th>
                        <Th>Name</Th>
                        <Th>Employee Code</Th>
                        <Th>Attendance</Th>
                        <Th>Penalties</Th>
                        <Th>OnTime/Late</Th>
                        <Th>Performance</Th>
                        <Th>Overtime Hours</Th>
                        <Th>Goal Achieved</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {data.map((row, index) => (
                    <Tr key={index}>
                    <Td>
                        <Avatar src={row.profile} name={row.name} />
                    </Td>
                    <Td>{row.name}</Td>
                    <Td>{row.employeeCode}</Td>
                    <Td>{row.attendance}</Td>
                    <Td>{row.penalties}</Td>
                    <Td>{row.onTimeLate}</Td>
                    <Td>{row.performance}</Td>
                    <Td>{row.overtimeHours}</Td>
                    <Td>{row.goalAchieved}</Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default Reports;
