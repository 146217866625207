import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { get, patch } from '../../services/apis/api';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Select,Divider, Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button } from '@chakra-ui/react';
import {
    Menu,
    MenuButton,
    MenuList,
    IconButton
} from '@chakra-ui/react'
import {  HamburgerIcon } from '@chakra-ui/icons';
import ViewPlayerRequest from '../../components/game-management/ViewPlayerRequest';
import ViewMeasurementRequest from '../../components/game-management/ViewMeasurementRequest';
import ViewCoachHiringRequest from '../../components/game-management/ViewCoachHiringRequest';
import ViewTrainingVisaRequest from '../../components/game-management/ViewTrainingVisaRequest';
import ViewAdministratorHireRequest from '../../components/game-management/ViewAdministratorHireRequest';
import ViewProfessionalPlayerVisaRequest from '../../components/game-management/ViewProfessionalPlayerVisaRequest';
import ViewPlayerPolarizationRequest from '../../components/game-management/ViewPlayerPolarizationRequest';
import ViewContractRequest from '../../components/game-management/ViewContractRequest';
import ViewPlayerPenaltyRequest from '../../components/game-management/ViewPlayerPenaltyRequest';
import ViewPlayerRemoveRequest from '../../components/game-management/ViewPlayerRemoveRequest';
import OpenRequestForm from '../../components/dialogueBoxes/OpenRequestForm';
import ViewTournamentRequest from '../../components/game-management/ViewTournamentRequest';
import ViewBuyPlayerProductRequest from '../../components/game-management/ViewBuyPlayerProductRequest';
import ViewExternalUserOrderRequest from '../../components/game-management/ViewExternalUserOrderRequest';
import ViewAlRwaadGeneralRequest from '../../components/operations/ViewAlRwaadGeneralRequest';
import { debounce } from 'lodash';

const expenseTypeOptions = [
    'hr-expense-requests',
    'tournament-finance-requests',
    'status-tracking',
    'salaries',
    'bonus',
    'buying-a-player'
];

const requestStatusOptions = [
    'pending-requests',
    'approved-requests',
    'rejected-requests',
];

const FinancialRequests = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [searchTerm, setSearchTerm] = useState('');
    const [filterRequest, setFilterRequest] = useState("");// Debounce the getAllRequest function
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [requests, setRequests] = useState([]);
    const [view, setView] = useState(false);
    const [selectedReq, setSelectedReq] = useState(null);
    const [role, setRole] = useState(null);
    
    // GET ALL AVAILABLE REQUESTS 
    const getAllRequest = async () => {
        try {
            let res;
            if (user) {
                if (activeTabIndex === 1) {
                    res = await get(`request/by-status/all?kind=${filterRequest}&approved=true&search=${searchTerm}`);
                } else if (activeTabIndex === 2) {
                    res = await get(`request/by-status/all?kind=${filterRequest}&rejected=true&search=${searchTerm}`);
                } else {
                    res = await get(`request/by-status/all?role=${user.role}&kind=${filterRequest}&pending=true&search=${searchTerm}`);
                }
                setRequests(res.data.requests || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

// Debounce the getAllRequest function
    const debouncedGetAllRequest = debounce(getAllRequest, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedGetAllRequest();
        // Cleanup function to cancel the debounced call on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [filterRequest, searchTerm, activeTabIndex]);


    // HANDLE USER ROLE 
    const handleRole = () => {
        if (user) {
            setRole(user.role);
        }
    };

    useEffect(() => {
        handleRole();
    }, [user]);

    // COMMENTEDFOR NOW BECAUSE OF TESTING NEW TABLE 
    // const RequestList = () => { 
    //     return (
    //         <div className="relative  flex-1 flex flex-col overflow-x-auto shadow-md sm:rounded-lg">
    //             <table className="w-full text-sm text-left rtl:text-right text-gray-500 flex-1 flex-col flex  ">
    //                 <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
    //                     <tr className='w-full flex items-center justify-between '>
    //                         <th scope="col" className="p-4">
    //                             <div className="flex items-center">
    //                                 {t('s-no')}
    //                             </div>
    //                         </th>
    //                         <th scope="col" className="px-6 py-3">
    //                             {t('requested-by')}
    //                         </th>
    //                         <th scope="col" className="px-6 py-3">
    //                             {t('request-type')}
    //                         </th>
    //                         <th scope="col" className="px-6 py-3">
    //                             {t('request-status')}
    //                         </th>
    //                         <th scope="col" className="px-6 py-3">
    //                             {t('action')}
    //                         </th>
    //                     </tr>
    //                 </thead>
    //                 <tbody className=' flex-1 flex flex-col  '>
    //                     {requests.length > 0 ? (
    //                         requests.map((request, index) => (
    //                             <tr key={request._id} className="bg-white border-b hover:bg-gray-50 flex items-center justify-between">
    //                                 <td className="w-4 p-4">
    //                                     <div className="flex items-center">
    //                                         {index + 1}
    //                                     </div>
    //                                 </td>
    //                                 <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
    //                                     <div className="text-base font-semibold">{request?.requestedBy?.name}</div>
    //                                 </th>
    //                                 <td className="px-6 py-4">
    //                                     {request.kind}
    //                                 </td>
    //                                 <td className="px-6 py-4">
    //                                     <div className="flex items-center">
    //                                         {request.status}
    //                                     </div>
    //                                 </td>
    //                                 <td className="px-6 py-4">
    //                                     <div className="flex items-center">
    //                                         <button onClick={() => { setView(true); setSelectedReq(request); }} className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md'>{t('view')}</button>
    //                                     </div>
    //                                 </td>
    //                             </tr>
    //                         ))
    //                     ) : (
    //                         <tr className='flex-1   flex items-center justify-center'>
    //                             {/* <td colSpan="5" className="px-6 py-4 text-center"> */}
    //                             {t('no-requests-found')}
    //                             {/* </td> */}
    //                         </tr>
    //                     )}
    //                 </tbody>
    //             </table>
    //         </div>
    //     );
    // };

    // LIST OF ALL REQUESTS
    const RequestList = () => {
        return (
          <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
            <Table variant="simple">
              <Thead bg="gray.50">
                <Tr>
                  <Th>{t('s-no')}</Th>
                  <Th>{t('requested-by')}</Th>
                  <Th>{t('request-type')}</Th>
                  <Th>{t('request-status')}</Th>
                  <Th>{t('action')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {requests.length > 0 ? (
                  requests.map((request, index) => (
                    <Tr key={request._id} _hover={{ bg: 'gray.50' }}>
                      <Td>{index + 1}</Td>
                      <Td>{request?.requestedBy?.name}</Td>
                      <Td>{request.kind}</Td>
                      <Td>{request.status}</Td>
                      <Td>
                        <Button 
                          colorScheme="blue" 
                          onClick={() => { setView(true); setSelectedReq(request); }}
                        >
                          {t('view')}
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="5" textAlign="center">
                      {t('no-requests-found')}
                    </Td>
                  </Tr> 
                )}
              </Tbody>
            </Table>
          </TableContainer>
        );
    };

    return (
        <>
         {view ? (
                <div>
                    {
                        // <ViewRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} />
                        selectedReq && selectedReq.kind === "playerApprovalRequest" ? <ViewPlayerRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "measurementRequest" ? <ViewMeasurementRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "coachHiringRequest" ? <ViewCoachHiringRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "trainingVisaRequest" ? <ViewTrainingVisaRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "administratorHireRequest" ? <ViewAdministratorHireRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "professionalPlayerVisaRequest" ? <ViewProfessionalPlayerVisaRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "playerPolarizationRequest" ? <ViewPlayerPolarizationRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "contractRequest" ? <ViewContractRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "playerRemoveRequest" ? <ViewPlayerRemoveRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "playerPenaltyRequest" ? <ViewPlayerPenaltyRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "tournamentRequest" ? <ViewTournamentRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "buyPlayerProductRequest" ? <ViewBuyPlayerProductRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "alRwaadGeneralRequest" ? <ViewAlRwaadGeneralRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                        selectedReq.kind === "externalUserOrderRequest" ? <ViewExternalUserOrderRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> : null
                    }
                </div>
            ) : (
            <div className='w-full flex-1 flex flex-col'>
                <div className={`flex     justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("financial-request")}</h2>
                    <div className='flex w-full justify-between lg:w-fit items-center'>
                        <div className="relative">
                            <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="table-search-users"
                                className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                placeholder={t("search-request")}
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                            />
                        </div>
                        <div className='ms-2'>
                            {/* <Select placeholder={t('select-filter')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" value={filterRequest} onChange={(e) => { setFilterRequest(e.target.value); }}>
                                {expenseTypeOptions.map((ite m) => (
                                    <option key={item} value={item}>
                                        {t(item)}
                                    </option>
                                ))}
                            </Select> */} 
                            <Menu closeonselect={false}>
                                <MenuButton
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<HamburgerIcon />}
                                    variant='outline'
                                />
                                <MenuList p={2}>
                                    <Select placeholder={t('expense-type')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" value={filterRequest} onChange={(e) => { setFilterRequest(e.target.value); }}>
                                        {expenseTypeOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {t(item)}
                                            </option>
                                        ))}
                                    </Select>
                                    <Divider my={1  } />
                                    <Select placeholder={t('request-status')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" value={filterRequest} onChange={(e) => { setFilterRequest(e.target.value); }}>
                                        {requestStatusOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {t(item)}
                                            </option>
                                        ))}
                                    </Select>
                                </MenuList>
                            </Menu>
                        </div>
                    </div>
                </div>

                <div className='w-full flex-1 flex flex-col '>
                    {/* <Tabs flex={1} display={'flex'} flexDirection={'column'} index={activeTabIndex} onChange={handleTabsChange} dir={isArabic ? 'rtl' : 'ltr'}>
                        <TabList >
                            <Tab>{t('pending-requests')}</Tab>
                            <Tab>{t('approved-requests')}</Tab>
                            <Tab>{t('rejected-requests')}</Tab>
                        </TabList>

                        <TabPanels flex={1} display={'flex'} flexDirection={'column'} >
                            <TabPanel px={0} flex={1} display={'flex'} flexDirection={'column'}>
                                <RequestList />
                            </TabPanel>
                            <TabPanel px={0} flex={1} display={'flex'} flexDirection={'column'}>
                                <RequestList />
                            </TabPanel>
                            <TabPanel px={0} flex={1} display={'flex'} flexDirection={'column'}>
                                <RequestList />
                            </TabPanel>
                        </TabPanels>
                    </Tabs> */}
                    <RequestList />
                </div>
            </div>
            )}
        </>
    )
}

export default FinancialRequests
