import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
} from "@chakra-ui/react";
import CreateEventForm from "../mediaCenter/CreateEventForm";

const CreateEvent = ({ getAllEvents, isEdit, event , setViewEvent}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button onClick={onOpen}> {isEdit ? `Edit`:`Create Event`}</Button>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isEdit ? `Edit Event`:`Create New Event`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div>
                            <CreateEventForm getAllEvents={getAllEvents} isEdit={isEdit} event={event} onClose={onClose} setViewEvent={setViewEvent}/>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateEvent;
