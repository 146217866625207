import { 
    Spinner, 
    useDisclosure, 
    useToast, 
    Box, 
    Badge, 
    Heading, 
    Text, 
    Button, 
    Flex, 
    SimpleGrid, 
    Input 
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { formatDateTime } from '../../utills/formateDate';

const statusRoleMapping = {
    'GAME-MANAGER-REVIEW': 'ACCOUNT-MANAGER-REVIEW',
    'ACCOUNT-MANAGER-REVIEW' : 'APPROVED',
};

const roleToTypeAndStatus = {
    'GAME-MANAGER': 'GAME-MANAGER-REVIEW',
    'ACCOUNT-MANAGER' : 'ACCOUNT-MANAGER-REVIEW',
};

const ViewTournamentRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const [approvedAmount, setApprovedAmount] = useState('');
    const toast = useToast();

    const getRequestDetails = async () => {
        try {
            const res = await get(`request/get/${requestId}`);
            if (res.statusCode === 200) {
                setRequest(res.data.request);
                const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request?.status;
                setCanApprove(userCanApprove);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch request details.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, [requestId]);

    const handleApprove = async () => {
        const nextStatus = statusRoleMapping[request?.status];
        if (!nextStatus) {
            toast({
                title: "Error",
                description: "Cannot approve this request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        try {
            const res = await patch(`request/update/tournament/${requestId}`, { status: nextStatus, approvedAmount });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                toast({
                    title: "Success",
                    description: res.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(res?.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to approve the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update/tournament/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
                getAllRequest();
                onClose();
                toast({
                    title: "Success",
                    description: "Request has been rejected.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(res.message);
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: "Error",
                description: error.message || "Failed to reject the request.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const confirmApprove = () => {
        if (request.status === 'ACCOUNT-MANAGER-REVIEW' && !approvedAmount) {
            toast({
                title: "Error",
                description: "Please enter the approved amount.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        handleApprove();
        onClose();
    };

    const handleApproveRejectRequest = (type) => {
        setRequestAction(type === "APPROVED");
        onOpen();
    };

    return (
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" maxWidth="1200px" mx="auto">
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Button onClick={() => setView(false)} colorScheme="blue" leftIcon={<IoMdArrowRoundBack />}>
                    Back
                </Button>
                {canApprove && (
                    <Box>
                        <Button onClick={() => handleApproveRejectRequest('REJECTED')} colorScheme="red" mr={3}>
                            {loading ? <Spinner size="sm" /> : "Reject Request"}
                        </Button>
                        <Button onClick={() => handleApproveRejectRequest('APPROVED')} colorScheme="blue">
                            {loading ? <Spinner size="sm" /> : "Approve Request"}
                        </Button>
                    </Box>
                )}
            </Flex>

            <Heading as="h2" size="lg" mb={4}>Request Details</Heading>
            <SimpleGrid columns={[1, null, 2]} spacing={5} mb={5}>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>Request By:</strong> <span className='text-[#485060]'>{request?.requestedBy?.name}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text>
                        <strong>Request Status:</strong> <Badge colorScheme={(request?.status === "APPROVED") ? 'green' : (request?.status === "REJECTED") ? 'red' : 'orange'}>{request?.status}</Badge>
                    </Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>Request Type:</strong> <span className='text-[#485060]'>{request?.kind}</span></Text>
                </Box>
                <Box bg="gray.100" p={4} borderRadius="md">
                    <Text><strong>Tournament Name:</strong> <span className='text-[#485060]'>{request?.tournamentId?.name}</span></Text>
                </Box>
            </SimpleGrid>

            <Heading as="h3" size="md" mb={2}>Tournament Details</Heading>
            <Box bg="gray.100" p={4} borderRadius="md">
                <SimpleGrid columns={[1, null, 2]} spacing={5}>
                    <Text><strong>Start Date:</strong> {request?.tournamentId?.startDate ? formatDateTime(request?.tournamentId?.startDate, 'date') : 'N/A'}</Text>
                    <Text><strong>End Date:</strong> {request?.tournamentId?.endDate ? formatDateTime(request?.tournamentId?.endDate, 'date') : 'N/A'}</Text>
                    <Text><strong>Location:</strong> {request?.tournamentId?.location || 'N/A'}</Text>
                    <Text><strong>Travel Date:</strong> {request?.tournamentId?.travelDate ? formatDateTime(request?.tournamentId?.travelDate, 'date') : 'N/A'}</Text>
                    <Text><strong>Transport Medium:</strong> {request?.tournamentId?.transportMedium || 'N/A'}</Text>
                    <Text><strong>Expected Departure:</strong> {request?.tournamentId?.expectedDeparture ? formatDateTime(request?.tournamentId?.expectedDeparture, 'date') : 'N/A'}</Text>
                    <Text><strong>Expected Arrival:</strong> {request?.tournamentId?.expectedArrival ? formatDateTime(request?.tournamentId?.expectedArrival, 'date') : 'N/A'}</Text>
                    <Text><strong>From:</strong> {request?.tournamentId?.from || 'N/A'}</Text>
                    <Text><strong>To:</strong> {request?.tournamentId?.to || 'N/A'}</Text>
                    <Text><strong>Team Name:</strong> {request?.tournamentId?.teamName || 'N/A'}</Text>
                    <Text><strong>Requested Amount:</strong> {request?.tournamentId?.requestedAmount || 'N/A'}</Text>
                    <Text><strong>Approved Amount:</strong> {request?.tournamentId?.approvedAmount || 'N/A'}</Text>
                </SimpleGrid>
            </Box>

            {canApprove && request.status === 'ACCOUNT-MANAGER-REVIEW' && (
                <Box mt={4}>
                    <Input
                        placeholder="Enter approved amount"
                        value={approvedAmount}
                        onChange={(e) => setApprovedAmount(e.target.value)}
                        type="number"
                        min={0}
                    />
                </Box>
            )}

            {requestAction ? (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Approve request"
                    description="Are you sure you want to approve this request?"
                    onConfirm={confirmApprove}
                    confirmButtonText="Approve"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="blue"
                />
            ) : (
                <CustomAlert
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Reject request"
                    description="Are you sure you want to reject this request?"
                    onConfirm={handleReject}
                    confirmButtonText="Reject"
                    cancelButtonText="Cancel"
                    confirmButtonColorScheme="red"
                />
            )}
        </Box>
    );
};

export default ViewTournamentRequest;
