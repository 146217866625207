import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    IconButton,
    useDisclosure,
    Tooltip,
} from '@chakra-ui/react';
import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import CreateAsset from '../../dialogBoxes/CreateAssets';
import { get } from '../../../../services/apis/api';
import ViewAssetDetailsModal from './ViewAssetDetailsModal';

const ComponentAssets = () => {
    const [assets, setAssets] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Fixed limit of 10 items per page
    const [totalPages, setTotalPages] = useState(1);
    const [selectedAsset, setSelectedAsset] = useState(null); // State to hold selected asset for viewing

    const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI's modal controls

    const getAssets = async () => {
        try {
            const res = await get(`assets/all?page=${page}&limit=${limit}&search=${search}`);
            if (res.statusCode === 200) {
                setAssets(res.data.data);
                setTotalPages(res.data.pages);
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [page, search]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset to page 1 when search changes
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    // Function to handle viewing the asset details
    const handleViewDetails = (asset) => {
        setSelectedAsset(asset);
        onOpen();
    };

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold">
                    Asset Management
                </Box>
                <Flex align="center">
                    <InputGroup mr={2}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search assets"
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    <CreateAsset getAssets={getAssets} isEdit={false} />
                </Flex>
            </Flex>

            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>SN.</Th>
                        <Th>Asset Name</Th>
                        <Th>Asset Model</Th>
                        <Th>Asset ID</Th>
                        <Th>Purchase Date</Th>
                        <Th>Status</Th>
                        <Th>Condition</Th>
                        <Th>Actions</Th> {/* Added "Actions" for buttons */}
                    </Tr>
                </Thead>
                <Tbody>
                    {assets.map((asset, index) => (
                        <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{asset?.name}</Td>
                            <Td>{asset?.model}</Td>
                            <Td>{asset?.serialNumber}</Td>
                            <Td>{new Date(asset?.purchaseDate).toLocaleDateString()}</Td>
                            <Td>{asset?.status}</Td>
                            <Td>{asset?.condition}</Td>
                            <Td className='flex space-x-2'>
                                {/* Action buttons: Edit and View */}
                                <CreateAsset getAssets={getAssets} isEdit={true} asset={asset} />
                                
                                <Tooltip label='View Asset'>
                                    <IconButton
                                        icon={<ViewIcon />}
                                        aria-label="View Asset Details"
                                        ml={2}
                                        onClick={() => handleViewDetails(asset)}
                                    />
                                </Tooltip>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Pagination Controls */}
            <Flex justify="space-between" align="center" mt={4}>
                <Button onClick={handlePrevPage} isDisabled={page === 1}>
                    Previous
                </Button>
                <Box>
                    Page {page} of {totalPages}
                </Box>
                <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                    Next
                </Button>
            </Flex>

            {/* Modal for viewing asset details */}
            {selectedAsset && (
                <ViewAssetDetailsModal
                    isOpen={isOpen}
                    onClose={onClose}
                    asset={selectedAsset}
                />
            )}
        </Box>
    );
};

export default ComponentAssets;
