'use client'

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  Button,
  Spinner,
  background,
} from '@chakra-ui/react'
import {
  FiMenu,
  FiBell,
  FiChevronDown,
  FiSettings,
} from 'react-icons/fi'
import logo from '../../assets/images/logo-rbg.png'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { get } from '../../services/apis/api';
import { useEffect, useState } from 'react';
import { getIconComponent } from '../../assets/icons/iconComponent';
import { deleteToken } from '../../services/apis/token';
import ConfirmBox from '../alerts/ConfirmBox';
import { UserState } from '../../context/user';
import { FaRegCircleUser } from 'react-icons/fa6';
import { TbLockCog } from 'react-icons/tb';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import colors from '../../utills/appTheme';
import ContactMahCompany from '../contactUs/ContactMahCompany';
import ChangeLanguage from '../settings/ChangeLanguage';


const SidebarContent = ({ onClose, ...rest }) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  
  const [linkItems, setLinkItems] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { user, setUser } = UserState();

  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const getNavigationData = async () => {
    try {
      if (currentPath === "admin") {
        setLinkItems([
          {
            "_id": "66a8559ea2d3855ca8db918c",
            "read": true,
            "create": true,
            "update": true,
            "delete": true,
            "name": "Clubs",
            "icon": "PiGameController ",
            "route": "/admin/clubs",
            "departmentId": "66431f38f3077244ebec5094",
            "serviceId": "669b53338e5e88ff803d99da"
          }
        ]);
      } else {
        const res = await get(`user-services/services?department=${currentPath}`);
        setLinkItems(res.data.navData);
      }
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        toast({
          title: 'Opps...!',
          description: "Please Connect to internet",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log("error--------->", error);
      }
    }
  }
  useEffect(() => {
    getNavigationData();
  }, [])

  const handleImgClick = () => {
    if (user.role === 'SUPER-ADMIN') {
      navigate('/dashboard')
    } else if (user.role.includes('HR')) {
      navigate('/hr')
    } else if (user.role.includes('ACCOUNT')) {
      navigate('/account')
    } else if (user.role.includes('STORE')) {
      navigate('/inventory')
    } else if (user.role.includes('MEDIA')) {
      navigate('/media-center')
    } else if (user.role.includes('OPERATION')) {
      navigate('/operation')
    } else if (user.role.includes('GAME')) {
      navigate('/game-management')
    } else if (user.role.includes('ORGANIZER')) {
      navigate('/admin')
    }
  }
  return (
    <Box
      transition="3s ease"
      bg={"#f8f9fa"}
      borderRight="1px"
      className="flex flex-col"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      pt={6}
      {...rest}>
      <div className=' w-full flex justify-end pe-4'>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </div>
      <div className=' w-full flex justify-center'>
        <Box h={28} w={32} className='border-[#e9ecef] border-b-[1px] px-4 pb-2'>
          <img onClick={handleImgClick} className='h-full w-full cursor-pointer' src={logo} />
        </Box>
      </div>
      <div className={`mt-2 h-[80vh] overflow-y-scroll ${isArabic ? 'rtl' : 'ltr'}`}>
        {
          linkItems && linkItems.map((link) => (

            <NavItem key={link.name} value={link.route} icon={link.icon} onClose={onClose}>
              {t(link.name)}
            </NavItem>
          ))
        }
        {/* <ContactMahCompany /> */}

      </div>
    </Box>
  )
}

const NavItem = ({ icon, value, children, onClose, ...rest }) => {
  const location = useLocation();
  const navroute = location.pathname;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <div className="group">
      <Link to={value} onClick={onClose}>
        <Box style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
          <Flex
            className={`text-sm font-semibold py-3 my-2 mx-5 rounded-md hover:shadow-sm flex items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}
            cursor="pointer"
            color={navroute == value ? 'black' : '#67748e'}
            bg={navroute == value ? '#ffffff' : ""}
            _hover={{
              bg: '#ffffff',
              color: 'black',
            }}
            {...rest}
          >

            {
              icon && (
                <Icon
                  mx="4"
                  fontSize="16"
                  borderRadius="10px"
                  bg="#ffffff"
                  p={2}
                  h={9}
                  w={9}
                  backgroundImage={
                    navroute == value ? colors.gradient : ""
                  }
                  color={navroute == value ? "#ffffff" : "#67748e"}
                  _groupHover={{
                    color: colors.primary,
                    backgroundImage: `linear-gradient(to right, [#ffffff], [#ffffff])`,
                  }}
                  as={getIconComponent(icon)}
                />
              )
            }
            {children}
          </Flex>
        </Box>
      </Link>
    </div>
  )
}


const MobileNav = ({ onOpen, user }) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={'#f8f9fa'}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      {/* <Box h={4} w={4}>
        <img className='h-full w-full' src={logo} />
      </Box> */}

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} pe={4} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm" className='text-sm font-medium'>{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600" className='font-semibold text-sm'>
                    {user?.jobTitle}
                  </Text>
                </VStack>
                <Avatar
                  size={'sm'}
                  src={user?.pic}
                />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown className='font-medium' />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')} className={`${isArabic ? 'rtl' : 'ltr'}`}>
              <MenuItem>
                <Link to={'/dashboard/settings'} className={`flex w-full items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                  <FiSettings className={"mx-4"} />  {t("settings")}
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/update-password'} className={`flex w-full items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                  <TbLockCog className={"mx-4"} /> {t("update-password")}
                </Link>
              </MenuItem>
              <div style={{ margin: '10px' }}>
                {t("change-language")}
                <ChangeLanguage />
              </div>
              <MenuDivider />
              <MenuItem >
                <ConfirmBox />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const CommanNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user, setUser } = UserState();
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    getUSer();
  }, []);

  const getUSer = async () => {
    const tkn = sessionStorage.getItem("token") || localStorage.getItem("token");
    const token = JSON.parse(tkn);
    if (!token) {
      navigate("/login");
    } else {
      try {
        const res = await get('user/get');
        if (res.statusCode === 200) {
          setUser(res.data.user);
        }
      } catch (error) {
        // navigate("/login");
        // toast({
        //   title: "Error Occured",
        //   description: error?.response?.data?.message,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });
      }
    }
  };

  if (!user) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <Spinner size={"xl"} />
      </div>
    )
  }

  return (
    <Box height="100vh" bg={'#ffffff'} display="flex" flexDirection="column">
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav user={user} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} overflow="auto" flex={1} display="flex" flexDirection="column" p="4">
        <Outlet />
      </Box>
    </Box>
  )
}

export default CommanNav


// const LinkItems = [
//     { name: 'Supplier', icon: FaUsersGear },
//     { name: 'Category', icon: MdOutlineCategory },
//     { name: 'Warehouse', icon: MdOutlineWarehouse },
//     { name: 'Invoice', icon: LiaFileInvoiceDollarSolid },
//     { name: 'Product', icon: FaDropbox },
//     { name: 'Sales Leadger', icon: BsGraphUpArrow },
//     { name: 'Expense', icon: RiMoneyRupeeCircleLine },
// ]

// //GAME MANAGER
// const LinkItems = [
//     { name: 'Team', value: "team", icon: RiTeamFill },
//     { name: 'Player', value: "player", icon: GiBabyfootPlayers },
//     { name: 'Coach', value: "coach", icon: FaHatCowboy },
//     { name: 'Request', value: "request", icon: RiAddFill },
// ]

// MEDIA CENTER
// const LinkItems = [
//   { name: 'Event', value: "/media-center/events", icon: MdEvent },
//   { name: 'Tournament', value: "/media-center/tournament", icon: TbTournament },
//   { name: 'Award', value: "/media-center/awards", icon: LiaAwardSolid },
//   { name: 'Notice', value: "/media-center/notices", icon: IoNotificationsCircleOutline },
//   { name: 'Role', value: "/operation/role", icon: TbUserPlus }
// ]

// // OPERATION
// const LinkItems = [
//   { name: 'Role', value: "role", icon: TbUserPlus },
//   { name: 'Game', value: "game", icon: PiGameController },
// ]

// SECRETARY
// const linkItems = [
//     { name: 'Registration', icon: MdAppRegistration },
//     { name: 'Training Plan', icon: FcPlanner },
//     { name: 'Contracts', icon: LiaFileContractSolid },
//     { name: 'Participation', icon: TiGroupOutline },
//     { name: 'Match Score', icon: MdSportsScore },
//     { name: 'Attendance', icon: RiPresentationFill },
//     { name: 'Request', icon: RiAddFill },
// ]