import axios from "axios"
import { getToken } from "./token";
//server urls--->
// export const baseUrl=`http://192.168.142.66:8000/api/v1/`    
// export const baseUrl=`http://localhost:8000/api/v1/`
export const baseUrl = `https://alibtisam.club/api/v1/`
// export const baseUrl=`https://alibtisam-backend.onrender.com/api/v1/`

//client urls--->
export const baseUrlClient = `http://localhost:3000/`
// export const baseUrl=`http://15.206.185.144:3100/api/`
// export const baseUrlClient=`http://localhost:3000/`



export const post = async (url, data) => {
    try {
        const res = await axios.post(baseUrl + url, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        })
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error);
        throw error;
    }
}

export const postFormData = async (url, data) => {
    try {
        const res = await axios.post(baseUrl + url, data,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error
    }
}

export const patchFormData = async (url, data) => {
    try {
        const res = await axios.patch(baseUrl + url, data,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error
    }
}

export const patch = async (url, data) => {
    try {
        const res = await axios.patch(baseUrl + url, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        })
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error;
    }
}

export const get = async (url) => {
    try {
        const res = await axios.get(baseUrl + url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        });
        return { statusCode: res.status, data: res.data }
    }
    catch (error) {
        // console.log(error)
        throw error;
    }
}

export const deletethis = async (url) => {
    try {
        const res = await axios.delete(baseUrl + url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        });
        return { statusCode: res.status, data: res.data }
    }
    catch (error) {
        // console.log(error)
        throw (error)
    }
}

