import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';

const Payroll = () => {
    const data = [
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ashu Aggrawal',
            employeeCode: 'MASE0009',
            paidDays: 25,
            payableDays: 23,
            grossPay: '86,350.00',
            netPay: '80,000.00',
            performance: '9.5',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Sumit Khare',
            employeeCode: 'SASLSI101',
            paidDays: 25,
            payableDays: 21,
            grossPay: '86,450.00',
            netPay: '80,000.00',
            performance: '9.5',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Gargi Jain',
            employeeCode: 'PNPVD2',
            paidDays: 25,
            payableDays: 23,
            grossPay: '90,000.00',
            netPay: '85,000.00',
            performance: '8.1',
        },
        // Add more data as needed
    ];

  return (
    <Box width="100%" p={4}>
        <Flex justify="space-between" align="center" mb={4}>
            <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                Payroll
            </Box>
            <Flex align="center">
            <Select placeholder="2023" w="100px" mr={2}>
                {/* Add more years as options */}
                <option value="2023">2023</option>
                <option value="2024">2024</option>
            </Select>
            <Select placeholder="January" w="150px" mr={2}>
                {/* Add all months as options */}
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
            </Select>
            <Button colorScheme="blue" mr={2}>
                Generate Payslips
            </Button>
            <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                    type="text"
                    placeholder="Search"
                    value={""}
                    onChange={()=>{}}
                />
            </InputGroup>
            <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                Download CSV
            </Button>
            </Flex>
        </Flex>
        <Table className="w-full text-sm text-left text-gray-500">
            <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
                <Th>Profile</Th>
                <Th>Name</Th>
                <Th>Employee Code</Th>
                <Th>Paid Days</Th>
                <Th>Payable Days</Th>
                <Th>Gross Pay</Th>
                <Th>Net Pay</Th>
                <Th>Performance</Th>
                <Th>Payslip</Th>
            </Tr>
            </Thead>
            <Tbody>
            {data.map((row, index) => (
                <Tr key={index}>
                <Td>
                    <Avatar src={row.profile} name={row.name} />
                </Td>
                <Td>{row.name}</Td>
                <Td>{row.employeeCode}</Td>
                <Td>{row.paidDays}</Td>
                <Td>{row.payableDays}</Td>
                <Td>{row.grossPay}</Td>
                <Td>{row.netPay}</Td>
                <Td>{row.performance}</Td>
                <Td>
                    <IconButton
                    icon={<ChevronDownIcon />}
                    aria-label="Download payslip"
                    />
                </Td>
                </Tr>
            ))}
            </Tbody>
        </Table>
    </Box>
  );
};

export default Payroll;
