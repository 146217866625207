import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';

const Assets = () => {
    const data = [
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ashu Aggrawal',
            employeeCode: 'MASE0009',
            assetType: 'Laptop',
            assetID: 'AS12345',
            assignedDate: '2023-01-15',
            status: 'Assigned',
            condition: 'Good',
            returnDate: '',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Sumit Khare',
            employeeCode: 'SASLSI101',
            assetType: 'Monitor',
            assetID: 'AS12346',
            assignedDate: '2023-02-10',
            status: 'Assigned',
            condition: 'Excellent',
            returnDate: '',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Gargi Jain',
            employeeCode: 'PNPVD2',
            assetType: 'Phone',
            assetID: 'AS12347',
            assignedDate: '2023-03-12',
            status: 'Returned',
            condition: 'Good',
            returnDate: '2023-05-10',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ravi Sharma',
            employeeCode: 'MASR009',
            assetType: 'Laptop',
            assetID: 'AS12348',
            assignedDate: '2023-04-14',
            status: 'Assigned',
            condition: 'Fair',
            returnDate: '',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Neha Mehta',
            employeeCode: 'NEHM101',
            assetType: 'Tablet',
            assetID: 'AS12349',
            assignedDate: '2023-05-08',
            status: 'Returned',
            condition: 'Good',
            returnDate: '2023-06-15',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Vikas Patel',
            employeeCode: 'VIKP102',
            assetType: 'Phone',
            assetID: 'AS12350',
            assignedDate: '2023-06-11',
            status: 'Assigned',
            condition: 'Excellent',
            returnDate: '',
        },
    ];

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                    Assets
                </Box>
                <Flex align="center">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={""}
                            onChange={()=>{}}
                        />
                    </InputGroup>
                    <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                        Download CSV
                    </Button>
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>Profile</Th>
                        <Th>Name</Th>
                        <Th>Employee Code</Th>
                        <Th>Asset Type</Th>
                        <Th>Asset ID</Th>
                        <Th>Assigned Date</Th>
                        <Th>Status</Th>
                        <Th>Condition</Th>
                        <Th>Return Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {data.map((row, index) => (
                    <Tr key={index}>
                    <Td>
                        <Avatar src={row.profile} name={row.name} />
                    </Td>
                    <Td>{row.name}</Td>
                    <Td>{row.employeeCode}</Td>
                    <Td>{row.assetType}</Td>
                    <Td>{row.assetID}</Td>
                    <Td>{row.assignedDate}</Td>
                    <Td>{row.status}</Td>
                    <Td>{row.condition}</Td>
                    <Td>{row.returnDate}</Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default Assets;
