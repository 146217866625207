import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    IconButton
} from '@chakra-ui/react';
import { patch, post } from '../../../services/apis/api';
import { EditIcon } from '@chakra-ui/icons';

const AddExpense = ({ getExpenses, isEdit, expense }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [formData, setFormData] = useState({
        expenseType: '',
        description: '',
        amount: '',
        paymentMethod: '',
        expenseDate: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (isEdit) {
            setFormData({
                expenseType: expense?.expenseType || '',
                description: expense?.description || '',
                amount: expense?.amount || '',
                paymentMethod: expense?.paymentMethod || '',
                expenseDate: expense?.expenseDate || '',
            });
        }
    }, [isEdit, expense]);

    const handleSubmit = async () => {
        try {
            let response;
            if (isEdit) {
                response = await patch(`hr-expense/update/${expense._id}`, formData);
            } else {
                response = await post('hr-expense/add', formData);
            }

            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Expense saved.',
                    description: 'Your expense has been saved successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getExpenses();
                setFormData({
                    employee: '',
                    expenseType: '',
                    description: '',
                    amount: '',
                    paymentMethod: '',
                    expenseDate: '',
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to save the expense.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <div>
            {
                isEdit ? 
                <IconButton
                    icon={<EditIcon />}
                    onClick={onOpen}
                    aria-label="Edit Expense"
                /> : 
                <Button onClick={onOpen} colorScheme="blue">
                    Add Expense
                </Button>
            }

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isEdit ? 'Edit Expense' : 'Add Expense'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="expenseType" isRequired mt={4}>
                            <FormLabel>Expense Type</FormLabel>
                            <Select
                                name="expenseType"
                                value={formData.expenseType}
                                onChange={handleInputChange}
                                placeholder="Select expense type"
                            >
                                <option value="Travel">Travel</option>
                                <option value="Accommodation">Accommodation</option>
                                <option value="Meals">Meals</option>
                                <option value="Training">Training</option>
                                <option value="Medical">Medical</option>
                                <option value="Equipment">Equipment</option>
                                <option value="Other">Other</option>
                            </Select>
                        </FormControl>

                        <FormControl id="description" isRequired mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder="Enter description"
                            />
                        </FormControl>

                        <FormControl id="amount" isRequired mt={4}>
                            <FormLabel>Amount</FormLabel>
                            <Input
                                name="amount"
                                type="number"
                                value={formData.amount}
                                onChange={handleInputChange}
                                placeholder="Enter the amount"
                            />
                        </FormControl>

                        <FormControl id="paymentMethod" isRequired mt={4}>
                            <FormLabel>Payment Method</FormLabel>
                            <Select
                                name="paymentMethod"
                                value={formData.paymentMethod}
                                onChange={handleInputChange}
                                placeholder="Select payment method"
                            >
                                <option value="cash">Cash</option>
                                <option value="credit-card">Credit Card</option>
                                <option value="bank-transfer">Bank Transfer</option>
                                <option value="other">Other</option>
                            </Select>
                        </FormControl>

                        <FormControl id="expenseDate" isRequired mt={4}>
                            <FormLabel>Expense Date</FormLabel>
                            <Input
                                type="date"
                                name="expenseDate"
                                value={formData.expenseDate ? new Date(formData.expenseDate).toISOString().split('T')[0] : ''}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mx={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default AddExpense;