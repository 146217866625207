import React, { useState } from "react";
import { InfoIcon } from "@chakra-ui/icons";
import { UserState } from "../../context/user";
import { useTranslation } from 'react-i18next';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Select, Divider, Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button } from '@chakra-ui/react';
import { FaStore } from "react-icons/fa";
import { GrVmMaintenance } from "react-icons/gr";
import { TbBrandMiniprogram } from "react-icons/tb"
// INCOME CATEGORY DATA 
const _incomeCategoryData = [
  {
    name: 'Player',
    icon: 'Player',
  },
  {
    name: ' Store Purchase',
    icon: 'store'
  },
  {
    name: 'Al-Rwaad Services',
    icon: 'al-rawaad'
  },
  {
    name: ' winning tournament',
    icon: 'ministry'
  },
  {
    name: 'maintenance Income',
    icon: 'maintenance'
  },
  {
    name: 'program support',
    icon: 'ministry'
  },
  {
    name: 'saudi olympics',
    icon: 'saudi-olympics'
  },
  {
    name: 'sold players',
    icon: 'sold-player'
  }
]

// EARNINGS CATEGORY DATA 
const _earningsData = [
  {
    label: 'total-earnings',
    amount: '$54,212',
    icon: 'ministry',
    change: '+2.5',
    bgColor: '#e1f5fe', // Light cyan for total earnings (positive)
  },
  {
    label: 'total-spendings',
    amount: '$54,212',
    icon: 'saudi-olympics',
    change: '-2.5',
    bgColor: '#ffebee', // Light red for total spendings (negative)
  },
  {
    label: 'total-goals',
    amount: '$54,212',
    icon: 'sold-player',
    change: '+2.5',
    bgColor: '#c8e6c9', // Light green for total goals (positive)
  }
];

// CATEGORY CARD 
const CategoryCard = ({ name, icon, href }) => {
  return (
    <div className='bg-[#f1f5f9] shadow-md lg:shadow-sm flex flex-col items-center justify-center gap-2 w-[49%] lg:w-1/5 md py-6 px-8 rounded-md mb-4'>
      {/* <InfoIcon w={6} h={6} /> */}
      {/* <FaStore size={28} /> */}
      <GrVmMaintenance size={28} />
      {/* <TbBrandMiniprogram size={28} /> */}
      <h6 className="text-sm whitespace-nowrap lg:text-base font-semibold capitalize">{name}</h6>
    </div>
  )
}

// EARNINGS CARD 
const EarningsCard = ({ earning }) => {
  return (
    <div style={{ backgroundColor: earning?.bgColor }} className={`bg-[#fcfbfb] shadow-md flex flex-col items-start gap-2 w-full md:w-[49%] lg:w-1/4 md py-6 px-8 rounded-md mb-4`}>
      <div className="w-full flex items-center  gap-2">
        <InfoIcon w={3} color="gray.500" />
        <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-500 font-medium capitalize">{earning?.label}</h6>
      </div>
      <h4 className="text-xl lg:text-3xl font-bold">{earning?.amount}</h4>
      <div className="w-full flex items-center  gap-2">
        <InfoIcon w={3} color="gray.500" />
        <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-600 font-medium"><span className={`${earning?.change > 0 ? 'text-green-500' : 'text-red-500'}`}>{earning?.change > 0 ? '+' : '-'}{Math.abs(earning?.change)}</span> from last week</h6>
      </div>
    </div>
  )
}

const Heading = ({ title }) => (
  <h2 className="text-base text-gray-600 mb-2 lg:text-xl font-bold">{title}</h2>
)

const EarningsAndIncome = () => {
  const { user } = UserState();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [filterRequest, setFilterRequest] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [requests, setRequests] = useState([]);
  const [selectedReq, setSelectedReq] = useState(null);
  const [view, setView] = useState(false);

  // LIST OF ALL REQUESTS
  const RequestList = () => {
    return (
      <TableContainer shadow="md" borderRadius="lg" flex={1} display="flex" flexDirection="column">
        <Table variant="simple">
          <Thead bg="gray.50">
            <Tr>
              <Th>{t('s-no')}</Th>
              <Th>{t('requested-by')}</Th>
              <Th>{t('request-type')}</Th>
              <Th>{t('request-status')}</Th>
              <Th>{t('action')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requests.length > 0 ? (
              requests.map((request, index) => (
                <Tr key={request._id} _hover={{ bg: 'gray.50' }}>
                  <Td>{index + 1}</Td>
                  <Td>{request?.requestedBy?.name}</Td>
                  <Td>{request.kind}</Td>
                  <Td>{request.status}</Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      onClick={() => { setView(true); setSelectedReq(request); }}
                    >
                      {t('view')}
                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="5" textAlign="center">
                  {t('no-requests-found')}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <div className='w-full flex-1 flex flex-col gap-3 lg:gap-4'>
        <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
          <h2 className=' text-xl lg:text-2xl  whitespace-nowrap font-bold'>{t("earnings-income")}</h2>
        </div>
        <div className="w-full flex flex-wrap gap-2 lg:gap-4 items-start">
          {_earningsData?.map((earning, index) => (
            <EarningsCard
              key={index}
              earning={earning}
            />
          ))}
        </div>
        <div className="w-full">
          <Heading
            title={t("income-categories")}
          />
          <div className="w-full flex flex-wrap py-4 gap-2 lg:gap-4 items-center justify-between">
            {_incomeCategoryData?.map((category, index) => (
              <CategoryCard
                key={index}
                name={category?.name}
                icon={category?.icon}
              />
            ))}
          </div>
        </div>
        <div className="w-full flex flex-col items-start gap-3 flex-1 min-h-[50vh]">
          <Heading
            title={t("recent-transections")}
          />
          <div className='w-full flex-1 flex flex-col '>
            <RequestList />
          </div>
        </div>
      </div>
    </>
  );
};

export default EarningsAndIncome;
