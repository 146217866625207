import React, { useEffect } from 'react';
import { Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdOutlineAccountBalance } from 'react-icons/md';
import { BsPeople, BsLayoutTextSidebarReverse, BsCart4 } from 'react-icons/bs';
import { FaUsersGear } from 'react-icons/fa6';
import { PiGameController } from 'react-icons/pi';
import colors from '../../utills/appTheme';

// LinkItems configuration with translation keys
const LinkItems = [
    { name: 'operation', value: "operation", icon: FaUsersGear },
    { name: 'account', value: "account", icon: MdOutlineAccountBalance },
    { name: 'human-resource', value: "hr", icon: BsPeople },
    { name: 'media-center', value: "media-center", icon: BsLayoutTextSidebarReverse },
    { name: 'store', value: "inventory", icon: BsCart4 },
    { name: 'game-management', icon: PiGameController, value: 'game-management' },
];

const Dashboard = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <div className={`w-full flex flex-wrap ${isArabic ? 'rtl' : 'ltr'}`}>
            {LinkItems.map((item, index) => (
                <div key={index} className='w-[300px] bg-[#f8f9fa] h-32 rounded-md shadow-md cursor-pointer m-2 transform transition duration-300 hover:scale-105 hover:shadow-md'>
                    <Link to={`/${item.value}`}>
                        <div className={`h-full w-full p-4 flex items-center justify-start ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                            <Icon
                                mx="4"
                                fontSize="16"
                                borderRadius={"10px"}
                                className='text-[#fff]'
                                style={{
                                    backgroundImage: colors.gradient,
                                }}
                                p={4}
                                h={16}
                                w={16}
                                color={'#ffffff'}
                                _groupHover={{
                                    color: 'white',
                                }}
                                as={item.icon}
                            />
                            <h2 className='text-xl font-semibold text-[#67748e]'>{t(item.name)}</h2>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default Dashboard;

