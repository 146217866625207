import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';

const Expense = () => {
    const data = [
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ashu Aggrawal',
            employeeCode: 'MASE0009',
            date: '2024-01-15',
            amount: '5,000.00',
            category: 'Travel',
            description: 'Client meeting travel expenses',
            status: 'Approved',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Sumit Khare',
            employeeCode: 'SASLSI101',
            date: '2024-01-10',
            amount: '3,000.00',
            category: 'Food',
            description: 'Business lunch with clients',
            status: 'Pending',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Gargi Jain',
            employeeCode: 'PNPVD2',
            date: '2024-01-12',
            amount: '2,500.00',
            category: 'Supplies',
            description: 'Office supplies purchase',
            status: 'Approved',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Ravi Sharma',
            employeeCode: 'MASR009',
            date: '2024-01-14',
            amount: '7,000.00',
            category: 'Training',
            description: 'Employee training program',
            status: 'Rejected',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Neha Mehta',
            employeeCode: 'NEHM101',
            date: '2024-01-08',
            amount: '4,500.00',
            category: 'Miscellaneous',
            description: 'Miscellaneous office expenses',
            status: 'Approved',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            name: 'Vikas Patel',
            employeeCode: 'VIKP102',
            date: '2024-01-11',
            amount: '6,000.00',
            category: 'Equipment',
            description: 'New office equipment purchase',
            status: 'Pending',
        },
    ];

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                    HR Expense
                </Box>
                <Flex align="center">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={""}
                            onChange={()=>{}}
                        />
                    </InputGroup>
                    <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                        Download CSV
                    </Button>
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>Profile</Th>
                        <Th>Name</Th>
                        <Th>Employee Code</Th>
                        <Th>Date</Th>
                        <Th>Amount</Th>
                        <Th>Category</Th>
                        <Th>Description</Th>
                        <Th>Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {data.map((row, index) => (
                    <Tr key={index}>
                    <Td>
                        <Avatar src={row.profile} name={row.name} />
                    </Td>
                    <Td>{row.name}</Td>
                    <Td>{row.employeeCode}</Td>
                    <Td>{row.date}</Td>
                    <Td>{row.amount}</Td>
                    <Td>{row.category}</Td>
                    <Td>{row.description}</Td>
                    <Td>{row.status}</Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default Expense;
