import React from 'react'
import { formatDateTime } from '../../utills/formateDate'
import CreateCoach from '../dialogueBoxes/CreateCoach'
import UpdatePassword from '../operations/dialogBoxes/Updatepassword'
import { useTranslation } from 'react-i18next'

const ViewCoachProfile = ({coach, setViewCoach, getAllCoaches}) => {

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (

        <div>
            <div className='flex justify-between p-5'> 
                <button onClick={()=>{setViewCoach(false)}} className='px-4 py-2 text-white bg-[#290dbd] rounded-md'>{t('back')}</button>
                {/* <AssigncoachtoTeam getAllcoachs={getAllcoachs} coachId={coach._id} name={coach.name} /> */}
                <div className="flex space-x-4"> {/* Added space between components */}
                    <UpdatePassword userId={coach._id} />
                    <CreateCoach getAllCoaches={getAllCoaches} coach={coach} isEdit={true} />
                </div>
            </div>
            <div dir={isArabic ? 'rtl' : 'ltr'}>
                <div class="container mx-auto my-5 p-5">
                    <div class="md:flex no-wrap md:-mx-2 ">
                        <div class="w-full md:w-3/12 md:mx-2">
                            <div class="bg-white p-3 border-t-4 border-[#290dbd]">
                                <div class="image overflow-hidden">
                                    <img class="h-auto w-full mx-auto"
                                        src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                                        alt="" />
                                </div>
                                <h1 className={`text-gray-900 font-bold text-xl leading-8 my-1 ${isArabic ? "text-right" : "text-left"}`}>{coach.name}</h1>
                                <h3 className={`text-gray-600 font-lg text-semibold leading-6 ${isArabic ? "text-right" : "text-left"}`}>{coach?.gameId?.name}</h3>
                                <p className={`text-sm text-gray-500 hover:text-gray-600 leading-6 ${isArabic ? "text-right" : "text-left"}`}>{coach?.city}</p>
                                <ul
                                    class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                    <li class="flex items-center py-3 justify-between w-full space-x-4">
                                        <span>{t("status")}</span>
                                        <span class="ml-auto"><span class="bg-green-500 py-1 px-2 rounded text-white text-sm">{t("active")}</span></span>
                                    </li>
                                    <li class="flex items-center py-3 justify-between w-full space-x-4">
                                        <span>{t('member-since')}</span>
                                        <span class="ml-auto">{"30 sept 2023"}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="my-4"></div>
                        </div>
                        <div class="w-full md:w-9/12 mx-2 h-64">
                        
                            <div class="bg-white p-3 shadow-sm rounded-sm">
                                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                    <span clas="text-green-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </span>
                                    <span class="tracking-wide">{t('about')}</span>
                                </div>
                                <div class="text-gray-700">
                                    <div class={`grid md:grid-cols-2 text-sm ${isArabic ? 'text-right' : ""}`}>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('name')}</div>
                                            <div class="px-4 py-2">{coach.name}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('user-name')}</div>
                                            <div class="px-4 py-2">{coach?.userName}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('gender')}</div>
                                            <div class="px-4 py-2">{coach.gender}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('contact-no')}</div>
                                            <div class="px-4 py-2">{coach.mobile}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('current-address')}</div>
                                            <div class="px-4 py-2">{coach?.address}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('permanent-address')}</div>
                                            <div class="px-4 py-2">{coach?.permanentAddress}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('email')}</div>
                                            <div class="px-4 py-2">
                                                <a class="text-blue-800" href="mailto:jane@example.com">{coach?.email}</a>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">{t('date-of-birth')}</div>
                                            <div class="px-4 py-2">{formatDateTime(coach?.dateOfBirth, 'date')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        
                            <div class="bg-white p-3 shadow-sm rounded-sm">
    
                                
                                
                            </div>
                            
                        </div>
                    </div>
                </div>     
            </div>
        </div>
    )
}

export default ViewCoachProfile