import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import InventoryHome from '../pages/inventory/InventoryHome';

const Account = () => {
  return (
    <Routes>
        <Route path="/account" element={<CommanNav />}>
            <Route index element={<InventoryHome />} />
        </Route>
    </Routes>
  )
}

export default Account
