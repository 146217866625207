import React from 'react';
import {
  Box, FormControl, FormLabel, Input, Grid, GridItem, Heading
} from '@chakra-ui/react';
import { EmployeeState } from '../../../../context/addEmployee';

const SalaryTerms = () => {
  const { salaryTerms, setSalaryTerms } = EmployeeState();

  console.log("salary terms--------->",salaryTerms);
  
  const handleChange = (e) => {
    const { id, value } = e.target;
    setSalaryTerms((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const inputStyles = {
    borderColor: 'gray.300',
    _focus: { borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' },
    borderRadius: 'md',
    background: 'white',
  };

  return (
    <Box p={5} mx="auto">
      <Heading size="md" mb={4}>Salary Terms</Heading>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6}>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="hra">
            <FormLabel>HRA</FormLabel>
            <Input 
              type="text" 
              id="hra" 
              placeholder="Enter HRA" 
              value={salaryTerms.hra || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="travelingAllowance">
            <FormLabel>Traveling Allowance</FormLabel>
            <Input 
              type="text" 
              id="travelingAllowance" 
              placeholder="Enter Traveling Allowance" 
              value={salaryTerms.travelingAllowance || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="hostelAllowance">
            <FormLabel>Hostel Allowance</FormLabel>
            <Input 
              type="text" 
              id="hostelAllowance" 
              placeholder="Enter Hostel Allowance" 
              value={salaryTerms.hostelAllowance || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="specialAllowance">
            <FormLabel>Special Allowance</FormLabel>
            <Input 
              type="text" 
              id="specialAllowance" 
              placeholder="Enter Special Allowance" 
              value={salaryTerms.specialAllowance || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="telecommunicationAllowance">
            <FormLabel>Telecommunication Allowance</FormLabel>
            <Input 
              type="text" 
              id="telecommunicationAllowance" 
              placeholder="Enter Telecommunication Allowance" 
              value={salaryTerms.telecommunicationAllowance || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="basicSalary">
            <FormLabel>Basic Salary</FormLabel>
            <Input 
              type="text" 
              id="basicSalary" 
              placeholder="Enter Basic Salary" 
              value={salaryTerms.basicSalary || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl id="grossPay">
            <FormLabel>Gross Pay</FormLabel>
            <Input 
              type="text" 
              id="grossPay" 
              placeholder="Enter Gross Pay" 
              value={salaryTerms.grossPay || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="variablePay">
            <FormLabel>Variable Pay</FormLabel>
            <Input 
              type="text" 
              id="variablePay" 
              placeholder="Enter Variable Pay" 
              value={salaryTerms.variablePay || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} mt={4}>
          <Heading size="md" mb={2}>Applicable Acts</Heading>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="pf">
            <FormLabel>PF</FormLabel>
            <Input 
              type="text" 
              id="pf" 
              placeholder="Enter PF" 
              value={salaryTerms.pf || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="pt">
            <FormLabel>PT</FormLabel>
            <Input 
              type="text" 
              id="pt" 
              placeholder="Enter PT" 
              value={salaryTerms.pt || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="esic">
            <FormLabel>ESIC</FormLabel>
            <Input 
              type="text" 
              id="esic" 
              placeholder="Enter ESIC" 
              value={salaryTerms.esic || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="gratuity">
            <FormLabel>Gratuity</FormLabel>
            <Input 
              type="text" 
              id="gratuity" 
              placeholder="Enter Gratuity" 
              value={salaryTerms.gratuity || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="bonus">
            <FormLabel>Bonus</FormLabel>
            <Input 
              type="text" 
              id="bonus" 
              placeholder="Enter Bonus" 
              value={salaryTerms.bonus || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="tds">
            <FormLabel>TDS</FormLabel>
            <Input 
              type="text" 
              id="tds" 
              placeholder="Enter TDS" 
              value={salaryTerms.tds || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="gst">
            <FormLabel>GST</FormLabel>
            <Input 
              type="text" 
              id="gst" 
              placeholder="Enter GST" 
              value={salaryTerms.gst || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }}>
          <FormControl id="workmenCompensation">
            <FormLabel>Workmen Compensation</FormLabel>
            <Input 
              type="text" 
              id="workmenCompensation" 
              placeholder="Enter Workmen Compensation" 
              value={salaryTerms.workmenCompensation || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl id="netPay">
            <FormLabel>Net Pay</FormLabel>
            <Input 
              type="text" 
              id="netPay" 
              placeholder="Enter Net Pay" 
              value={salaryTerms.netPay || ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl id="appraisalDate">
            <FormLabel>Appraisal Date</FormLabel>
            <Input 
              type="date" 
              id="appraisalDate" 
              placeholder="Enter Appraisal Date" 
              value={salaryTerms.appraisalDate ? new Date(salaryTerms.appraisalDate).toISOString().split('T')[0]  : ''} 
              onChange={handleChange} 
              {...inputStyles}
            />
          </FormControl>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SalaryTerms;
