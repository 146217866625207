import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Text,
  VStack,
  HStack,
  Divider,
  Grid,
  GridItem,
  useDisclosure
} from '@chakra-ui/react';
import { FaFileInvoiceDollar, FaPrint, FaEnvelope } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';

const PayslipModal = ({ payslip }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalRef = React.useRef();

  // Function to handle printing
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    documentTitle: 'Payslip',
  });

  return (
    <>
      {/* Icon button to open modal */}
      <IconButton
        icon={<FaFileInvoiceDollar />}
        onClick={onOpen}
        aria-label="Open Payslip"
      />

      {/* Payslip modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent ref={modalRef}>
          <ModalHeader>Payslip for : {payslip.employee.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={8}>
            <VStack align="start" spacing={4}>
              {/* Employee Information */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Employee Code:</Text>
                    <Text>{payslip.employee.employeeCode}</Text>
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Payroll Month:</Text>
                    <Text>{new Date(payslip.payrollMonth).toLocaleDateString()}</Text>
                  </HStack>
                </GridItem>
              </Grid>

              <Divider />

              {/* Salary Breakdown */}
              <Text fontWeight="bold" fontSize="lg">Salary Breakdown:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                {Object.entries(payslip.salaryBreakdown).map(([key, value]) => (
                  <GridItem key={key}>
                    <HStack justify="space-between" width="full">
                      <Text textTransform="capitalize">{key.replace(/([A-Z])/g, ' $1')}</Text>
                      <Text>₹ {value.toFixed(2)}</Text>
                    </HStack>
                  </GridItem>
                ))}
              </Grid>

              <Divider />

              {/* Deductions */}
              <Text fontWeight="bold" fontSize="lg">Deductions:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                {Object.entries(payslip.deductions).map(([key, value]) => (
                  <GridItem key={key}>
                    <HStack justify="space-between" width="full">
                      <Text textTransform="capitalize">{key.replace(/([A-Z])/g, ' $1')}</Text>
                      <Text>₹ {value.toFixed(2)}</Text>
                    </HStack>
                  </GridItem>
                ))}
              </Grid>

              <Divider />

              {/* Leave Information */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Paid Leaves:</Text>
                    <Text>{payslip.paidLeaves}</Text>
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Unpaid Leaves:</Text>
                    <Text>{payslip.unpaidLeaves}</Text>
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Paid Days:</Text>
                    <Text>{payslip.paidDays}</Text>
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">Payable Days:</Text>
                    <Text>{payslip.payableDays}</Text>
                  </HStack>
                </GridItem>
              </Grid>

              <Divider />

              {/* Final Net Pay */}
              <HStack justify="space-between" width="full">
                <Text fontWeight="bold" fontSize="xl">Final Net Pay:</Text>
                <Text fontWeight="bold" fontSize="xl" color="green.500">
                  ₹ {payslip.finalNetPay.toFixed(2)}
                </Text>
              </HStack>

              {/* Payment Status */}
              <HStack justify="space-between" width="full">
                <Text fontWeight="bold">Payment Status:</Text>
                <Text color={payslip.paymentStatus === 'PENDING' ? 'red.500' : 'green.500'}>
                  {payslip.paymentStatus}
                </Text>
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            {/* Buttons to mail and print */}
            <Button colorScheme="blue" leftIcon={<FaEnvelope />} mr={3}>
              Mail Payslip
            </Button>
            <Button colorScheme="green" leftIcon={<FaPrint />} onClick={handlePrint}>
              Print Payslip
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PayslipModal;
