import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import InventoryHome from '../pages/inventory/InventoryHome';
import Expenses from '../pages/account/Expenses';
import FinancialRequests from '../pages/account/FinancialRequests';
import EarningsAndIncome from '../pages/account/EarningsIncomes';

const Account = () => {
  return (
    <Routes>
        <Route path="/account" element={<CommanNav />}>
            <Route index element={<InventoryHome />} />
            <Route path='request' element={<FinancialRequests />} />
            <Route path='expenses' element={<Expenses />} />
            <Route path='payroll' element={<Expenses />} />
            <Route path='assets' element={<Expenses />} />
            <Route path='reports' element={<Expenses />} />
            <Route path='earnings' element={<EarningsAndIncome />} />
        </Route>
    </Routes>
  )
}

export default Account
