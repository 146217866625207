import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import AddExpense from '../../components/hr/dialogBoxes/CreateHrExpense'; // Assuming this is the modal for adding an expense
import { get } from '../../services/apis/api';

const Expense = () => {
    const [expenses, setExpenses] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Fixed limit of 10 items per page
    const [totalPages, setTotalPages] = useState(1);

    const getExpenses = async () => {
        try {
            const res = await get(`hr-expense/all?page=${page}&limit=${limit}&search=${search}`);
            if (res.statusCode === 200) {
                setExpenses(res.data.data);
                setTotalPages(res.data.pages);
            }
        } catch (error) {
            console.error('Error fetching expenses:', error);
        }
    };
    

    useEffect(() => {
        getExpenses();
    }, [page, search]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset to page 1 when search changes
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold">
                    HR Expense
                </Box>
                <Flex align="center">
                    <InputGroup mr={2}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search expenses"
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    <AddExpense getExpenses={getExpenses} isEdit={false} />
                </Flex>
            </Flex>

            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>Expense</Th>
                        <Th>Date</Th>
                        <Th>Amount</Th>
                        <Th>Category</Th>
                        <Th>Description</Th>
                        <Th>Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {expenses.map((expense, index) => (
                        <Tr key={index}>
                            <Td>
                                <Avatar src={'https://cdn-icons-png.flaticon.com/512/4470/4470504.png'} name={expense?.employee?.name} />
                            </Td>
                            <Td>{new Date(expense?.expenseDate).toLocaleDateString()}</Td>
                            <Td>{expense?.amount}</Td>
                            <Td>{expense?.expenseType}</Td>
                            <Td>{expense?.description}</Td>
                            <Td>{expense?.approvalStatus}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Pagination Controls */}
            <Flex justify="space-between" align="center" mt={4}>
                <Button onClick={handlePrevPage} isDisabled={page === 1}>
                    Previous
                </Button>
                <Box>
                    Page {page} of {totalPages}
                </Box>
                <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                    Next
                </Button>
            </Flex>
        </Box>
    );
};

export default Expense;
