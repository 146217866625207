import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { get, patch, post } from '../../services/apis/api';
import CreateEvent from '../../components/dialogueBoxes/CreateEvent';
import { formatDateTime } from '../../utills/formateDate';
import ViewEvent from '../../components/mediaCenter/ViewEvent';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [viewEvent, setViewEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const getAllEvents = async () => {
    try {
      const res = await get('event/all');
      console.log(res);
      setEvents(res.data.events);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const handleSearch = async () => {
    try {
      const res = await get(`event/all?name=${searchQuery}`);
      console.log(res);
      setEvents(res.data.events);
    } catch (error) {
      console.error('Error searching events:', error);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const handleCheckboxChange = async (id, value) => {
    try {

      const res = await post(`event/update-event-status/${id}`, { active: value });
      console.log(res);
      if (res.statusCode === 200) {
        getAllEvents()
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {viewEvent ? (
        <ViewEvent event={selectedEvent} setViewEvent={setViewEvent} fetchEvents={getAllEvents} />

      ) : (
        <div>
          <div className='flex justify-between px-2 items-center mb-4'>
            <div>
              <div className='relative'>
                <div className='absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none'>
                  <svg
                    className='w-4 h-4 text-gray-500'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                    />
                  </svg>
                </div>
                <input
                  type='text'
                  id='table-search-users'
                  className='block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
                  placeholder='Search for events'
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            <div>
              <CreateEvent getAllEvents={getAllEvents} isEdit={false} />
            </div>
          </div>
          <div>
            <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
              <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
                <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                  <tr>
                    <th scope='col' className='p-4'>
                      <div className='flex items-center'>S.No.</div>
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Event Name
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Category
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Date
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Time
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Location
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      Status
                    </th>
                    <th scope='col' className='px-6 py-3'>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {events
                    .filter((event) =>
                      event.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((event, index) => (
                      <tr
                        className='bg-white border-b hover:bg-gray-50 '
                        key={event._id}
                      >
                        <td className='w-4 p-4'>
                          <div className='flex items-center'>{index + 1}</div>
                        </td>
                        <th
                          scope='row'
                          className='flex items-center px-6 py-4 text-gray-900 whitespace-nowrap '
                        >
                          <div className='text-base font-semibold'>{event.name}</div>
                        </th>
                        <td className='px-6 py-4'>{event.category}</td>
                        <td className='px-6 py-4'>
                          <div className='flex items-center'>
                            {formatDateTime(event.dateTime, 'date')}
                          </div>
                        </td>
                        <td className='px-6 py-4'>
                          <div className='flex items-center'>
                            {formatDateTime(event.dateTime, 'time')}
                          </div>
                        </td>
                        <td className='px-6 py-4'>
                          <div className='flex items-center'>{event.location}</div>
                        </td>
                        <td className="px-6 py-4 flex items-center">
                          <label className="inline-flex items-center cursor-pointer me-8">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              checked={event.active}
                              onChange={() =>
                                handleCheckboxChange(
                                  event._id,
                                  !event.active
                                )
                              }
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                          </label>
                        </td>
                        <td className='px-6 py-4'>
                          <div className='flex items-center'>
                            <button
                              onClick={() => {
                                setSelectedEvent(event);
                                setViewEvent(true);
                              }}
                              className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md'
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;
