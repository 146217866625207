import React from 'react'
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import { InfoIcon } from '@chakra-ui/icons';


// expenseS CATEGORY DATA 

const _expensesData = [
    {
        label: 'total-expenses',
        amount: '$5,42,212',
        icon: 'ministry',
        change: '+2.5',
        bgColor: '#e0f7fa', // Light blue for total expenses
    },
    {
        label: 'hr-expenses',
        amount: '$54,212',
        icon: 'saudi-olympics',
        change: '-2.5',
        bgColor: '#ffebee', // Light red for HR expenses
    },
    {
        label: 'tournament-expenses',
        amount: '$54,212',
        icon: 'sold-player',
        change: '+2.5',
        bgColor: '#c8e6c9', // Light green for tournament expenses
    },
    {
        label: 'events-expenses',
        amount: '$54,212',
        icon: 'sold-player',
        change: '+2.5',
        bgColor: '#fff9c4', // Light yellow for events expenses
    },
    {
        label: 'payroll-salaries-expenses',
        amount: '$54,212',
        icon: 'sold-player',
        change: '+2.5',
        bgColor: '#ffe0b2', // Light orange for payroll and salaries expenses
    },
    {
        label: 'player-expenses',
        amount: '$54,212',
        icon: 'sold-player',
        change: '+2.5',
        bgColor: '#f0f4c3', // Light lime for player expenses
    },
]


// EXPENSE CARD 
const ExpenseCard = ({ expense }) => {
    return (
        <div style={{ backgroundColor: expense.bgColor }} className={` shadow-md hover:scale-105 transition-all duration-300  flex flex-col items-start gap-2 w-full md:w-[49%] lg:w-[32%] py-6 px-8 rounded-md mb-4`}>
            <div className="w-full flex items-center  gap-2">
                <InfoIcon w={3} color="gray.500" />
                <h6 className="text-sm whitespace-nowrap  lg:text-xs text-gray-500 font-medium capitalize">{expense?.label}</h6>
            </div>
            <h4 className="text-xl lg:text-3xl font-bold">{expense?.amount}</h4>
            <div className="w-full flex items-center  gap-2">
                <InfoIcon w={3} color="gray.500" />
                <h6 className="text-sm whitespace-nowrap lg:text-xs text-gray-600 font-medium"><span className={`${expense?.change > 0 ? 'text-green-500' : 'text-red-500'}`}>{expense?.change > 0 ? '+' : '-'}{Math.abs(expense?.change)}</span> from last week</h6>
            </div>
        </div>
    )
}

const Expenses = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <>
            <div className='w-full flex-1 flex flex-col'>
                <div className={`flex justify-between px-0 lg:px-2 mb-4 ${isArabic ? 'flex-col lg:flex-row-reverse items-end lg:items-center ' : 'flex-col lg:flex-row items-start lg:items-center'}`}>
                    <h2 className='text-xl whitespace-nowrap font-bold mb-4'>{t("expenses")}</h2>
                </div>
                <div className="w-full flex flex-wrap justify-between py-4 gap-2 lg:gap-4 items-start">
                    {_expensesData?.map((expense, index) => (
                        <ExpenseCard
                            key={index}
                            expense={expense}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default Expenses
