import React from 'react';
import {
  Box, Button, Checkbox, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Textarea, VStack, HStack, Grid, GridItem, Image, IconButton
} from '@chakra-ui/react';
import { EmployeeState } from '../../../../context/addEmployee';
import { CloseIcon } from '@chakra-ui/icons';

const PersonalInfo = () => {
  const { personalInfo, setPersonalInfo } = EmployeeState();

  console.log("persnal-info----->", personalInfo);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setPersonalInfo((prevDetails) => ({
        ...prevDetails,
        agreements: { ...prevDetails.agreements, [name]: checked },
      }));
    } else {
      setPersonalInfo((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleDocumentChange = (event) => {
    setPersonalInfo((prevDetails) => ({
      ...prevDetails,
      documents: [...prevDetails.documents, ...Array.from(event.target.files)],
    }));
  };

  const handleRemoveDocument = (indexToRemove) => {
    setPersonalInfo((prevDetails) => ({
      ...prevDetails,
      documents: prevDetails.documents.filter((_, index) => index !== indexToRemove),
    }));
  };

  const inputStyles = {
    borderColor: 'gray.300',
    borderRadius: 'md',
    _hover: { borderColor: 'gray.500' },
    _focus: { borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' },
  };

  return (
    <Box p={5} borderRadius="md" bg="white" mx="auto">
      <VStack spacing={4} align="stretch">
        <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="alternate-contact">
              <FormLabel>Alternate Contact No</FormLabel>
              <Input
                type="text"
                name="alternateContact"
                placeholder="Enter Alternate Contact No"
                value={personalInfo.alternateContact || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="aadhar-no">
              <FormLabel>Aadhar No</FormLabel>
              <Input
                type="text"
                name="aadharNo"
                placeholder="Enter Aadhar No"
                value={personalInfo.aadharNo || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="pan-card">
              <FormLabel>PAN Card No</FormLabel>
              <Input
                type="text"
                name="panCardNo"
                placeholder="Enter PAN Card No"
                value={personalInfo.panCardNo || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="driving-license">
              <FormLabel>Driving License</FormLabel>
              <Input
                type="text"
                name="drivingLicense"
                placeholder="Enter Driving License"
                value={personalInfo.drivingLicense || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="pay-method">
              <FormLabel>Pay Method</FormLabel>
              <RadioGroup
                name="payMethod"
                value={personalInfo.payMethod || ''}
                onChange={(value) =>
                  setPersonalInfo((prevDetails) => ({ ...prevDetails, payMethod: value }))
                }
              >
                <Stack direction="row">
                  <Radio value="Cash">Cash</Radio>
                  <Radio value="Bank">Bank</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="holder-name">
              <FormLabel>Holder Name</FormLabel>
              <Input
                type="text"
                name="holderName"
                placeholder="Enter Holder Name"
                value={personalInfo.holderName || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="account-no">
              <FormLabel>Account No</FormLabel>
              <Input
                type="text"
                name="accountNo"
                placeholder="Enter Account No"
                value={personalInfo.accountNo || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 1 }}>
            <FormControl id="bank-name">
              <FormLabel>Bank Name</FormLabel>
              <Input
                type="text"
                name="bankName"
                placeholder="Enter Bank Name"
                value={personalInfo.bankName || ''}
                onChange={handleChange}
                {...inputStyles}
              />
            </FormControl>
          </GridItem>
        </Grid>
        <FormControl id="permanent-address">
          <FormLabel>Permanent Address</FormLabel>
          <Textarea
            name="permanentAddress"
            placeholder="Enter Permanent Address"
            value={personalInfo.permanentAddress || ''}
            onChange={handleChange}
            {...inputStyles}
          />
        </FormControl>
        <FormControl id="current-address">
          <FormLabel>Current Address</FormLabel>
          <Checkbox
            name="sameAsPermanentAddress"
            isChecked={personalInfo.currentAddress === personalInfo.permanentAddress}
            onChange={() =>
              setPersonalInfo((prevDetails) => ({
                ...prevDetails,
                currentAddress: prevDetails.permanentAddress
              }))
            }
          >
            Same as permanent address
          </Checkbox>
          <Textarea
            name="currentAddress"
            placeholder="Enter Current Address"
            value={personalInfo.currentAddress || ''}
            onChange={handleChange}
            {...inputStyles}
          />
        </FormControl>
        <FormControl id="attach-documents">
          <FormLabel>Attach Documents</FormLabel>
          <Input type="file" multiple onChange={handleDocumentChange} />
          <Stack direction="row" spacing={4} mt={2} wrap="wrap">
            {personalInfo.documents && personalInfo.documents.map((doc, index) => (
              <Box key={index} position="relative">
                <Image src={typeof doc === 'string' ? doc : URL.createObjectURL(doc)} alt={`document-${index}`} boxSize="100px" borderRadius="md" />
                <IconButton
                  icon={<CloseIcon />}
                  size="xs"
                  position="absolute"
                  top="0"
                  right="0"
                  onClick={() => handleRemoveDocument(index)}
                  bg="red.500"
                  color="white"
                  borderRadius="full"
                  aria-label="Remove document"
                />
              </Box>
            ))}
          </Stack>
        </FormControl>
        <FormControl id="agreements" className='flex flex-col'>
          <FormLabel>Agreements</FormLabel>
          <Checkbox
            name="agreeDetails"
            isChecked={personalInfo.agreements.agreeDetails || false}
            onChange={handleChange}
          >
            I, the employee, agree with the following statements
          </Checkbox>
          <Checkbox
            name="agreeTerms"
            isChecked={personalInfo.agreements.agreeTerms || false}
            onChange={handleChange}
          >
            I, the employee, understand the terms
          </Checkbox>
        </FormControl>
      </VStack>
    </Box>
  );
};

export default PersonalInfo;
