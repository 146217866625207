import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';

const Announcements = () => {
    const data = [
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            title: 'New Year Holiday',
            description: 'Company will be closed on January 1st for New Year.',
            date: '2023-12-31',
            postedBy: 'HR Department',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            title: 'Quarterly Meeting',
            description: 'Quarterly meeting will be held on January 15th.',
            date: '2023-01-10',
            postedBy: 'Management Team',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            title: 'Team Outing',
            description: 'Team outing scheduled for February 20th.',
            date: '2023-02-15',
            postedBy: 'Team Lead',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            title: 'Training Session',
            description: 'Mandatory training session on March 5th.',
            date: '2023-03-01',
            postedBy: 'HR Department',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            title: 'Product Launch',
            description: 'New product launch event on April 10th.',
            date: '2023-04-05',
            postedBy: 'Marketing Team',
        },
        {
            profile: 'https://via.placeholder.com/150', // Replace with actual image URLs
            title: 'Policy Update',
            description: 'New company policies will be effective from May 1st.',
            date: '2023-04-25',
            postedBy: 'HR Department',
        },
    ];

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                    Announcements
                </Box>
                <Flex align="center">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={""}
                            onChange={()=>{}}
                        />
                    </InputGroup>
                    <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                        Download CSV
                    </Button>
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>Profile</Th>
                        <Th>Title</Th>
                        <Th>Description</Th>
                        <Th>Date</Th>
                        <Th>Posted By</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {data.map((row, index) => (
                    <Tr key={index}>
                    <Td>
                        <Avatar src={row.profile} name={row.postedBy} />
                    </Td>
                    <Td>{row.title}</Td>
                    <Td>
                        <Text noOfLines={1}>{row.description}</Text>
                    </Td>
                    <Td>{row.date}</Td>
                    <Td>{row.postedBy}</Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default Announcements;
