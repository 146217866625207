import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./pages/authentication/Login";
import SideNav from "./components/globles/SideNav";
import Dashboard from "./pages/superAdmin/Dashboard";
import MediaCenter from "./routing/MediaCenter";
import Home from "./pages/Home";
import Navbar from "./components/globles/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faqs from "./pages/Faqs";
import Events from "./pages/Events";
import Operation from "./routing/Operation";
import Account from "./routing/Account";
import GameManagement from "./routing/GameManagement";
import CreateClub from "./pages/CreateClub";
import HumanResource from "./routing/HumanResource";
import Inventory from "./routing/Inventory";
import NetworkError from "./pages/common/NetworkError";
import { NetworkStatusContext } from "./context/networkStatusContext";
import { useToast } from '@chakra-ui/react'
import Products from "./pages/Products";
import Admin from "./routing/Admin";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import UpdatePassword from "./pages/authentication/UpdatePassword";
import Profile from "./pages/common/Profile";
import AllSettings from "./pages/settings/AllSettings";

function App() {
  
  const { isOnline, setLastVisitedPath, lastVisitedPath } = useContext(NetworkStatusContext);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast()

  useEffect(() => {
    if (!isOnline && location.pathname !== '/network-error') {
      setLastVisitedPath(location.pathname);
      navigate('/network-error');
      toast({
        title: 'You are offline. Please check your internet connection.',
        description: "",
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    }
  }, [isOnline, location.pathname, setLastVisitedPath, navigate]);
  
  return (
    <div className="">
      <Inventory />
      <MediaCenter />
      <HumanResource />
      <Operation />
      <Account />
      <GameManagement />
      <Admin />
      <Routes>
        <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="faq" element={<Faqs />} />
            <Route path="events" element={<Events />} />
            <Route path="products" element={<Products />} />
        </Route>

        <Route path="/dashboard" element={<SideNav />}>
            <Route index element={<Dashboard />} />
            <Route path="settings" element={<AllSettings />} />
        </Route>
           
        <Route path="create-club" element={<CreateClub />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/network-error" element={<NetworkError />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
