import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { patch } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';

const accessRoles = [
    "COACH",
    "INTERNAL USER",
    "GUARDIAN",
    "EXTERNAL USER",
    "ALRWAAD USER"
]

const UpdateApplicationAccess = ({service, getApplicationServices}) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    useEffect(() => {
        setRoles(accessRoles.map(role => ({ label: role, value: role })))
        setSelectedRoles(service.access.map(role => ({ label: role, value: role })))
    }, []);

    const handleSelectChange = (selectedOptions) => {
        setSelectedRoles(selectedOptions);
    };

    const updateAccess = async()=>{
        try {
            const access = selectedRoles.map(role => role.value);
            const res = await patch(`org-application-service/update/${service._id}`, { access });
            if(res.statusCode === 200){
                getApplicationServices();
                onClose();
                toast({
                    title: 'Success!',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Oops!',
                description: error?.response?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }

    return (
        <div>
            <button onClick={onOpen} className='bg-[#290dbd] text-[#ffffff] px-4 py-1 font-medium text-base rounded-md'>
                {t("edit")}
            </button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{t("edit-access-roles")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Select
                    isMulti
                    options={roles}
                    value={selectedRoles}
                    onChange={handleSelectChange}
                    placeholder="Select roles..."
                    />
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='gray' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme='blue' onClick={() => {updateAccess()}}>
                        Save
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default UpdateApplicationAccess;
