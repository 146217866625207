import React from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  IconButton,
  Input,
  Image,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { formatDateTime } from '../../utills/formateDate';

const employees = [
    {
      name: 'Ashu Aggrawal',
      employeeCode: 'MASE0009',
      gender: 'Male',
      designation: 'Software Engineer',
      mobile: '9876543210',
      joiningDate: '2021-06-15',
      pic: 'https://via.placeholder.com/150',
      email: 'ashu.aggrawal@example.com'
    },
    {
      name: 'Sumit Khare',
      employeeCode: 'SASLSI101',
      gender: 'Male',
      designation: 'Project Manager',
      mobile: '9876543211',
      joiningDate: '2019-01-20',
      pic: 'https://via.placeholder.com/150',
      email: 'sumit.khare@example.com'
    },
    {
      name: 'Gargi Jain',
      employeeCode: 'PNPVD2',
      gender: 'Female',
      designation: 'UI/UX Designer',
      mobile: '9876543212',
      joiningDate: '2020-03-10',
      pic: 'https://via.placeholder.com/150',
      email: 'gargi.jain@example.com'
    }
    // Add more dummy employees if needed
];

const Fnf = () => {
   
  return (
    <Box width="100%" p={4}>
        <Flex justify="space-between" align="center" mb={4}>
            <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                FNF
            </Box>
            <Flex align="center">
                <Input placeholder="Search..." w="200px" mr={2} />
                <IconButton icon={<SearchIcon />} aria-label="Search" mr={2} />
                <Button leftIcon={<DownloadIcon />} colorScheme="gray">
                    Download CSV
                </Button>
            </Flex>
        </Flex>
        <Table className="w-full text-sm text-left text-gray-500">
          <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
              <Th>Name</Th>
              <Th>Employee Code</Th>
              <Th>Gender</Th>
              <Th>Designation</Th>
              <Th>Mobile Number</Th>
              <Th>Date of Joining</Th>
            </Tr>
          </Thead>
          <Tbody>
            {employees.map(employee => (
              <Tr key={employee.employeeCode} onClick={()=>{}} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
                <Td className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                  <Image className="w-10 h-10 rounded-full" src={employee.pic} alt="Employee image" />
                  <Box ml={3}>
                    <Text className="text-base font-semibold">{employee.name}</Text>
                    <Text className="font-normal text-gray-500">{employee.email}</Text>
                  </Box>
                </Td>
                <Td>{employee.employeeCode}</Td>
                <Td>{employee.gender}</Td>
                <Td>{employee.designation}</Td>
                <Td>{employee.mobile}</Td>
                <Td>{formatDateTime(employee.joiningDate, 'date')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
    </Box>
  );
};

export default Fnf;
