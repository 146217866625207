import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    useDisclosure,
    useToast,
    Spinner,
    SimpleGrid,
} from '@chakra-ui/react';
import Select from 'react-select';
import { get, post } from '../../../services/apis/api';

const AddSingleEmployeeAttendance = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [employeeId, setEmployeeId] = useState(null);
    const [monthEndDate, setMonthEndDate] = useState('');
    const [paidDays, setPaidDays] = useState('');
    const [payableDays, setPayableDays] = useState('');
    const [overTimeHours, setOverTimeHours] = useState('');
    const [leaveDays, setLeaveDays] = useState('');
    const [absentDays, setAbsentDays] = useState('');
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllEmployees();
    }, []);

    const getAllEmployees = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {                
                const employeeOptions = res.data.dropdown.map(employee => ({
                    value: employee._id,
                    label: employee.name,
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async () => {
        if (!employeeId || !monthEndDate || paidDays === '' || payableDays === '' || overTimeHours === '' || leaveDays === '' || absentDays === '') {
            toast({
                title: 'Error',
                description: 'All fields are required.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            const data = {
                employeeId,
                monthEndDate,
                paidDays,
                payableDays,
                overTimeHours,
                leaveDays,
                absentDays,
            };

            const res = await post('attendance/add', data);
            if (res.statusCode === 201) {
                onClose();
                toast({
                    title: 'Success',
                    description: res.data.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
            toast({
                title: 'Error',
                description: 'Failed to create attendance record.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button onClick={onOpen} colorScheme='blue' className='me-2'>Add Attendance</Button>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Monthly Attendance</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl mb={4}>
                            <FormLabel>Employee</FormLabel>
                            <Select
                                options={employees}
                                value={employees.find(emp => emp.value === employeeId)}
                                onChange={(selectedOption) => setEmployeeId(selectedOption.value)}
                                placeholder="Select Employee"
                            />
                        </FormControl>

                        {/* SimpleGrid for two items in a row */}
                        <SimpleGrid columns={2} spacing={4}>
                            <FormControl mb={4}>
                                <FormLabel>Month End Date</FormLabel>
                                <Input
                                    type="date"
                                    value={monthEndDate}
                                    onChange={(e) => setMonthEndDate(e.target.value)}
                                />
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel>Paid Days</FormLabel>
                                <Input
                                    type="number"
                                    value={paidDays}
                                    onChange={(e) => setPaidDays(e.target.value)}
                                />
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel>Payable Days</FormLabel>
                                <Input
                                    type="number"
                                    value={payableDays}
                                    onChange={(e) => setPayableDays(e.target.value)}
                                />
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel>Overtime Hours</FormLabel>
                                <Input
                                    type="number"
                                    value={overTimeHours}
                                    onChange={(e) => setOverTimeHours(e.target.value)}
                                />
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel>Leave Days</FormLabel>
                                <Input
                                    type="number"
                                    value={leaveDays}
                                    onChange={(e) => setLeaveDays(e.target.value)}
                                />
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel>Absent Days</FormLabel>
                                <Input
                                    type="number"
                                    value={absentDays}
                                    onChange={(e) => setAbsentDays(e.target.value)}
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} colorScheme="gray" mr={3}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} colorScheme="blue">
                            {loading ? <Spinner size="sm" /> : 'Save'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddSingleEmployeeAttendance;
