import React, { useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Select,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
} from '@chakra-ui/react';
import { ArrowBackIcon, DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import PerformancePlan from '../../components/hr/components/performance/PerformancePlan';
const data = [];
const Performances = () => {
    const [openPlans, setOpenPlans] = useState(false);
 
    console.log("plans------>",openPlans);
    
   
    return (
        <>
            {
                openPlans ? <PerformancePlan setOpenPlans={setOpenPlans} /> : 
                <Box width="100%" p={4}>
                    <Flex justify="space-between" align="center" mb={4}>
                        <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                            Performances
                        </Box>
                        <Flex align="center">
                            <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                                <InputLeftElement pointerEvents="none">
                                    <SearchIcon color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder="Search"
                                    value={""}
                                    onChange={()=>{}}
                                />
                            </InputGroup>
                            <Select placeholder="2023" w="100px" mr={2}>
                                {/* Add more years as options */}
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </Select>
                            <Select placeholder="January" w="150px" mr={2}>
                                {/* Add all months as options */}
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </Select>
                            <Button leftIcon={<DownloadIcon />} colorScheme="gray" mr={2} mb={{ base: 2, md: 0 }}>
                                Download CSV
                            </Button>
                            <Button onClick={()=>{setOpenPlans(true)}} colorScheme="blue" mb={{ base: 2, md: 0 }}>
                                Performance Plans
                            </Button>
                        </Flex>
                    </Flex>
                    <Table className="w-full text-sm text-left text-gray-500">
                        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <Tr>
                            <Th>Profile</Th>
                            <Th>Name</Th>
                            <Th>Employee Code</Th>
                            <Th>Designation</Th>
                            <Th>Todos</Th>
                            <Th>Leaves</Th>
                            <Th>Reporting Head PP</Th>
                            <Th>Performance</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        {data.map((row, index) => (
                            <Tr key={index}>
                            <Td>
                                <Avatar src={row.profile} name={row.name} />
                            </Td>
                            <Td>{row.name}</Td>
                            <Td>{row.employeeCode}</Td>
                            <Td>{row.designation}</Td>
                            <Td>{row.todos}</Td>
                            <Td>{row.leaves}</Td>
                            <Td>{row.reportingHeadPP}</Td>
                            <Td>
                                <Input placeholder="Enter performance" value={row.performance} />
                            </Td>
                            </Tr>
                        ))}
                        </Tbody>
                    </Table>
                </Box>
            }
        </>
        
    );
};

export default Performances;
